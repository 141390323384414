const yelpHardCodedReviews = [
  {
    userName: "Cheryl D.",
    rating: 5,
    text: "This facility is awesome! My family member was there for two weeks and during that time his care was exceptional...",
  },
  {
    userName: "Nancy H.",
    rating: 5,
    text: "Mountain Manor is one of the best facilities around. It's clean, wonderful staff and excellent Physical Therapy department. This is a place where the staff really cares about the patient...",
  },
  {
    userName: "MJ L.",
    rating: 5,
    text: "I was blessed to be able to spend two weeks at Mountain Manor as I transitioned from hospital after leg/ knee surgery and before going home. The nurses, physical therapists and occupational therapists are excellent in their specialties and really care for their patients...",
  },
  {
    userName: "R B.",
    rating: 5,
    text: "They took excellent care of our mom. Treated her like family. The staff always answered our many questions. They also called to inform us of any concern about her...",
  },
  {
    userName: "Bradd H.",
    rating: 5,
    text: "I was very fortunate to get my father and his wife both in this wonderful facility. Kim and Mountain Manor made every accommodation to get them both in the same room. The entire staff is caring and gentle with the patients...",
  },
  {
    userName: "Allen M.",
    rating: 5,
    text: "Thank you Mountian Manor for providing the best physical therapy and care for my loved one. Each time I visited all the appropriate precautions were taken when checking in. It's up to date and always clean, all the nurses are efficient and compassionate...",
  },
  {
    userName: "Arvin P.",
    rating: 5,
    text: "A good and friendly environment a good place for rehabilitation, room cleanliness has everything you need...",
  },
  {
    userName: "Bandari A.",
    rating: 5,
    text: "I actually appreciate that. The administration seemed very approachable and responsive to our needs. In comparison to several other buildings we visited, Mountain Manor definitely gets two thumbs up...",
  },
  {
    userName: "Jake A.",
    rating: 5,
    text: "It's never easy leaving a loved one in the care of someone else, but my mom's experience at Mountain Manor was phenomenal. The administrator really goes above and beyond and made the admission process painless...",
  },
  {
    userName: "Cam C.",
    rating: 5,
    text: "Jim the owner helped me out of a jam. My mom had a stroke and was in the hospital and transferred to the physical therapy unit. The hospital abruptly called me to tell me she was being discharged. I pleaded with them for an extra day to find care since my mom could no longer care for herself after the stroke...",
  },
  {
    userName: "Dahlia F.",
    rating: 5,
    text: "I highly recommend Mountain Manor to everyone. It boasts fabulous Rehabilitation and Nursing services, such as Occupational, Physical and Speech Therapies as well as fantastic personalized care plans, pain management and so much more...",
  },
  {
    userName: "M M.",
    rating: 5,
    text: "I have nothing but good things to say about Mountain Manor. I stayed there for two weeks with a pelvic fracture. It's not a luxury accommodation but everything was clean and the staff was attentive, friendly and upbeat...",
  },
];

export default yelpHardCodedReviews;
