import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

import Moment from "moment";

const Invoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [showPaidInvoices, setShowPaidInvoices] = useState(false);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    // Set time zone to UTC
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

    const month = date.getUTCMonth() + 1; // Get UTC month
    const day = date.getUTCDate(); // Get UTC day
    const year = date.getUTCFullYear(); // Get UTC year

    // Pad single digit month and day with leading zero if necessary
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    return `${formattedMonth}/${formattedDay}/${year}`;
  };

  const columns = [
    {
      Header: "Invoice Number",
      accessor: "invoice_number",
    },
    {
      Header: "Description",
      accessor: "invoice_description",
    },
    {
      Header: "From Date",
      accessor: "invoice_from_date",
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: "To Date",
      accessor: "invoice_to_date",
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: "Company Billed to",
      accessor: "invoice_bill_company_to",
    },
    {
      Header: "Invoice Total Cost",
      accessor: "invoice_total_cost",
      Cell: ({ value }) => {
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      },
    },
    {
      Header: "Invoice Paid?",
      accessor: "invoice_paid",
      Cell: ({ value, row }) => {
        const personTo = row._original.invoice_bill_person_to;
        const lastName = personTo.split(" ").pop();
        if (value === "No") {
          return (
            <a
              href={`https://jordenmcdonald.com/invoice-details/${row._original.invoice_number}/${lastName}`}
              style={{
                borderRadius: "25px ",
                color: "white",
                padding: "10px",
                backgroundColor: "#007BFF",
                border: "none",
                display: "block",
                width: "100%",
              }}
            >
              <i
                className="fa fa-credit-card"
                style={{ paddingRight: "5px", color: "white" }}
              />
              Pay Invoice
            </a>
          );
        } else {
          return (
            <button
              style={{
                width: "100%",
                borderRadius: "25px ",
                color: "white",
                padding: "10px",
                backgroundColor: "green",
                border: "none",
                outline: "none",
              }}
            >
              {value}
              <i className="fa fa-check" style={{ paddingLeft: "5px" }} />
            </button>
          );
        }
      },
    },
  ];

  const getInvoices = async () => {
    const url = "https://jordenmcdonald.com/api/selectInvoicesByFacility";
    const requestBody = {
      company: "Mountain Manor",
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    const data = await response.json();
    setInvoices(data);
  };

  useEffect(() => {
    getInvoices();
  }, []);

  const filteredInvoices = showPaidInvoices
    ? invoices.filter((invoice) => invoice.invoice_paid === "Yes")
    : invoices.filter((invoice) => invoice.invoice_paid === "No");

  return (
    <div className="container">
      <div className="invoices-container">
        <h1 className="py-3">Mountain Manor Invoices</h1>
        <i className="fa fa-credit-card fa-5x pb-3" aria-hidden="true" />
        <p className="pb-3" style={{ fontSize: "20px" }}>
          To pay an invoice simply click on the "Pay Invoice" button and it will
          redirect you to the invoice to begin payment electronically through
          our secure payment gateway.
        </p>
        <div className="invoice-button-action d-flex justify-content-end mb-3">
          <button
            onClick={() => setShowPaidInvoices(!showPaidInvoices)}
            style={{
              borderRadius: "25px",
              color: "white",
              padding: "10px",
              backgroundColor: "#007BFF",
              border: "none",
              cursor: "pointer",
            }}
          >
            {showPaidInvoices ? "Show Unpaid Invoices" : "Show Paid Invoices"}
          </button>
        </div>

        <ReactTable
          data={filteredInvoices}
          columns={columns}
          defaultPageSize={10}
          filterable
          className="-striped -highlight"
        />
      </div>
    </div>
  );
};

export default Invoices;
