import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";

const About = () => {
  const [activeSelected, setActiveSelected] = useState(0);

  const headerContent = [
    {
      title: "About Us",
      img: "/images/mm-about.webp",
      alt: "Mountain Manor About Us",
      header: "About Mountain Manor",
      content:
        "Mountain Manor Skilled Nursing & Assisted Living is multi-level of care senior living home in Carmichael, CA. We offer a 47-bed skilled nursing facility and 33-bed Assisted Living Facility all under the same roof. Since 2015 under our current ownership, Mountain Manor has grown to become a preferred destination for anybody needing post-acute rehabilitation. Mountain Manor maintains an overall 5-Star CMS rating and achieved a rare perfect survey on our 2017 annual CMS recertification survey.",
    },
    {
      title: "Meet the Owner",
      img: "/images/mmaboutowner.webp",
      alt: "Mountain Manor Owner Jim Jordan",
      header: "Meet Jim Jordan",
      content:
        "James Jordan is a 30+ year veteran in the healthcare industry. In 2015 he saw a need for a different type of skilled nursing facility when he purchased Mountain Manor. Mountain Manor is the product of his vision for a boutique family-owned SNF with extra emphasis on customer service and personalized care.  Jim’s vision has quickly turned Mountain Manor into a preferred and popular destination for post-acute rehabilitation.",
    },
    {
      title: "Affiliations",
      img: "/images/mmaboutaffiliations.webp",
      alt: "Mountain Manor Affiliations",
      header: "Mountain Manor Affiliations",
      content:
        "Mountain Manor Skilled Nursing Overall 5-Star Medicare Rating and is a member of Dignity Health's Preferred Network list. As the best Skilled Nursing Facility in Carmichael, CA. We offer Physical, Occupational and Speech Therapies, in addition to compassionate care by Licensed RN's, LVN's and staff.",
    },
  ];

  const showContent = (e, listId) => {
    setActiveSelected(listId);
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="aboutContainer">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mountain Manor About Us</title>
        <meta
          name="description"
          content="Mountain Manor History | About Us | Accolades"
        />
        <link
          rel="canonical"
          href="https://mountainmanorskillednursing.com/aboutUs"
        />
      </Helmet>
      <div className="fluidContainer">
        <div className="bgimage">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-md-6 col-md-auto">
                <div
                  className="bgContent"
                  style={{
                    background: "#000",
                    borderRadius: "25px",
                    opacity: 0.8,
                  }}
                >
                  <div data-aos="fade-down" data-aos-once="true">
                    <h1 className="text-center">About Us</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="whymmContainer">
        <div className="container">
          <div className="h2Header">
            <h2>Why Mountain Manor</h2>
          </div>
          <div className="listHeaders">
            <div className="row justify-content-md-center">
              <div className="col-md-12 text-center">
                <ul>
                  {headerContent.map((rows, index) => (
                    <li
                      key={"list- " + index}
                      className={
                        activeSelected === index
                          ? "headerList selected"
                          : "headerList"
                      }
                      onClick={(e) => showContent(e, index)}
                    >
                      {rows.title}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="contentContainer">
          <div className="fluid-container">
            {headerContent.map((rows, index) =>
              activeSelected === index ? (
                <div className="row align-items-center" key={"list- " + index}>
                  <div className="col-sm-12 col-md-6">
                    <img
                      className="col-sm-12 w-100"
                      src={rows.img}
                      alt={rows.alt}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 pr-5">
                    <h3 className="pl-3 pb-3 pt-3" style={{ color: "#06a7d3" }}>
                      {rows.header}
                    </h3>
                    <p className="col-sm-12 pb-3">{rows.content}</p>
                  </div>
                </div>
              ) : null
            )}
          </div>
        </div>
        {/* Additional content... */}
      </div>
    </div>
  );
};

export default About;
