import React from "react";

const ImportantInfo = () => {
  return (
    <div className="container">
      <h2 className="p-4 text-center">Important Information</h2>

      <div className="importantInfo border p-3">
        <div className="disclaimer text-center">
          <h1 className="text-center">
            <i className="fas fa-info-circle fa-2x"></i>
          </h1>
          <h2 className="text-center pt-3">
            This Scheduling Module Collects Personal Information
          </h2>
          <h3>
            <strong>What information do we collect?</strong>
          </h3>
          <p>
            When you submit a scheduling appointment through our scheduling
            module, we collect the following:
          </p>
          <ul>
            <li>Full Name</li>
            <li>Email Address</li>
            <li>Telephone</li>
            <li>Resident Name</li>
            <li>
              Communication Preferred Method Tool I.E Skype, Viber, FaceTime,
              Zoom
            </li>
          </ul>
          <h3>
            <strong>How do we collect this information?</strong>
          </h3>
          <p>
            When you schedule an appointment through our scheduler, we store it
            in our database.
          </p>
          <h3>
            <strong>Why do we collect this information?</strong>
          </h3>
          <p>
            In order to save your scheduling appointment, we have to store it in
            our database. Therefore, we have a reference point to what schedules
            users have made when they schedule an appointment.
          </p>
          <h3>
            <strong>Is my personal information secured?</strong>
          </h3>
          <p>
            All data that gets collected and stored in our database, is using
            HTTPS encryption as well as client and server-side validation to
            make sure your information is fully secured and encrypted.
          </p>
          <h3>
            <strong>We do not share your personal information</strong>
          </h3>
          <p>
            Any data that gets collected through our scheduler{" "}
            <strong>DOES NOT</strong> get shared with any third parties and is
            only for purposes of displaying appointment availability.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ImportantInfo;
