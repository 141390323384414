import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

import Moment from "moment";
import { Modal, Button } from "react-bootstrap";
import dataTip from "data-tip";
import { confirmAlert } from "react-confirm-alert";

import admindashboardService from "../../services/Adminservice";

class Modifyusers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adminData: [],
      pages: null,
      show: false,
      showAdd: false,
      loading: true,
      email: "",
      firstName: "",
      lastName: "",
      roleName: "",
      userId: "",
      addEmail: "",
      addfirstName: "",
      addlastName: "",
      addroleName: "",
      addPassword: ""
    };
  }

  handleClose = () => {
    this.setState({
      show: false,
      showAdd: false
    });
  };

  handleShow = async (email, firstName, lastName, userId) => {
    await this.setState({
      email: email,
      firstName: firstName,
      lastName: lastName,
      userId: userId,
      show: true
    });
  };

  handleaddShow = () => {
    this.setState({
      showAdd: true
    });
  };

  onChange = async e => {
    await this.setState({
      [e.target.name]: e.target.value
    });
  };

  adminData = async () => {
    const getData = await admindashboardService.adminselectUsers();

    this.setState({
      adminData: getData
    });

    //console.log(this.state);
  };

  addUser = async e => {
    e.preventDefault();
    // If select box wasn't changed
    if (this.state.addroleName === "") {
      await this.setState({
        addroleName: 1
      });
    }

    const addData = {
      email: this.state.addEmail,
      firstName: this.state.addfirstName,
      lastName: this.state.addlastName,
      password: this.state.addPassword,
      roleId: this.state.addroleName
    };

    const adduserAction = await admindashboardService.adminaddUser(addData);

    await this.adminData();

    this.setState({
      showAdd: false
    });
    //console.log(this.state);
  };

  editUser = async e => {
    e.preventDefault();

    if (this.state.addroleName === "") {
      await this.setState({
        roleName: 1
      });
    }

    const data = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      roleId: this.state.roleName,
      userId: this.state.userId,
      dateModified: Moment().format("YYYY-MM-DD hh:mm:ss")
    };

    const editAction = await admindashboardService.admineditUser(data);

    await this.adminData();

    // close the modal after action
    this.setState({
      show: false
    });
  };

  deleteUser = userId => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure</h1>
            <p>You want to delete this user?</p>
            <button onClick={onClose}>Cancel</button>
            <button
              onClick={() => {
                this.confirmDelete(userId);
                onClose();
              }}
            >
              Confirm
            </button>
          </div>
        );
      }
    });
  };

  confirmDelete = async userId => {
    // Delete the user
    const deleteUser = await admindashboardService.admindeleteUser({
      userId: userId
    });

    // Re-render the table
    await this.adminData();
  };

  componentDidMount = async () => {
    await this.adminData();
  };

  render() {
    return (
      <div className="fluid-container">
        <div className="container">
          {/* Edit Modal */}
          <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <i className="fas fa-user-circle" /> Edit User
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="modifyuserContainer">
                <form
                  method="POST"
                  autoComplete="off"
                  onSubmit={e => this.editUser(e)}
                >
                  <div className="form-group">
                    <label htmlFor="email" className="mb-2">
                      Email Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      onChange={e => this.onChange(e)}
                      defaultValue={this.state.email}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="firstName" className="mb-2">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="firstName"
                      onChange={e => this.onChange(e)}
                      defaultValue={this.state.firstName}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="lastName" className="mb-2">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="lastName"
                      onChange={e => this.onChange(e)}
                      defaultValue={this.state.lastName}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="roleName" className="mb-2">
                      Role Name
                    </label>
                    <select
                      className="form-control"
                      name="roleName"
                      onChange={e => this.onChange(e)}
                      value={this.state.roleName}
                    >
                      <option value="1">Admin</option>
                      <option value="2">Content Manager</option>
                    </select>
                  </div>
                  <button type="submit" className="btn btn-default">
                    Submit
                  </button>
                </form>
              </div>
            </Modal.Body>
          </Modal>
          {/* Add Modal */}
          <Modal show={this.state.showAdd} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <i className="fas fa-user-circle" /> Add User
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="modifyuserContainer">
                <form
                  method="POST"
                  autoComplete="off"
                  onSubmit={e => this.addUser(e)}
                >
                  <div className="form-group">
                    <label htmlFor="email" className="mb-2">
                      Email Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="addEmail"
                      onChange={e => this.onChange(e)}
                      value={this.state.addEmail}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="firstName" className="mb-2">
                      First Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="addfirstName"
                      onChange={e => this.onChange(e)}
                      value={this.state.addfirstName}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="lastName" className="mb-2">
                      Last Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="addlastName"
                      onChange={e => this.onChange(e)}
                      value={this.state.addlastName}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password" className="mb-2">
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      name="addPassword"
                      onChange={e => this.onChange(e)}
                      value={this.state.addPassword}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="addroleName" className="mb-2">
                      Role Name
                    </label>
                    <select
                      className="form-control"
                      name="addroleName"
                      onChange={e => this.onChange(e)}
                      value={this.state.addroleName}
                    >
                      <option value="1">Admin</option>
                      <option value="2">Content Manager</option>
                    </select>
                  </div>
                  <button type="submit" className="btn btn-default">
                    Submit
                  </button>
                </form>
              </div>
            </Modal.Body>
          </Modal>
          <span className="adduserContainer data-tip-right" data-tip="Add User">
            <i className="fas fa-plus fa-2x" onClick={this.handleaddShow} />
          </span>
          <ReactTable
            columns={[
              {
                Header: "User ID",
                accessor: "user_id",
                style: {
                  textAlign: "center"
                }
              },
              {
                Header: "Email Address",
                accessor: "email",
                style: {
                  textAlign: "center"
                }
              },
              {
                Header: "First Name",
                accessor: "firstName",
                style: {
                  textAlign: "center"
                }
              },
              {
                Header: "Last Name",
                accessor: "lastName",
                style: {
                  textAlign: "center"
                }
              },
              {
                Header: "Role Name",
                accessor: "role_name",
                style: {
                  textAlign: "center"
                }
              },
              {
                Header: "Date Created",
                id: "date_created",
                accessor: d => {
                  return Moment(d.date_created).format("MM-DD-YYYY hh:mm:ss a");
                },
                style: {
                  textAlign: "center"
                },
                sortable: false,
                filterable: false
              },
              {
                Header: "Actions",
                style: {
                  textAlign: "center"
                },
                sortable: false,
                filterable: false,
                Cell: props => {
                  return (
                    <div className="actionContainer">
                      <span
                        className="editUser data-tip-left"
                        data-tip="Edit User"
                      >
                        <i
                          className="fas fa-edit"
                          style={{ padding: "0 10px", cursor: "pointer" }}
                          onClick={() =>
                            this.handleShow(
                              props.original.email,
                              props.original.firstName,
                              props.original.lastName,
                              props.original.user_id
                            )
                          }
                          //onClick={() => console.log(props)}
                        />
                      </span>
                      <span
                        className="deleteUser data-tip-right"
                        data-tip="Delete User"
                      >
                        <i
                          className="far fa-trash-alt"
                          style={{ padding: "0 10px", color: "red" }}
                          onClick={() =>
                            this.deleteUser(props.original.user_id)
                          }
                        />
                      </span>
                    </div>
                  );
                }
              }
            ]}
            //manual // Forces table not to paginate or sort automatically, so we can handle it server-side
            data={this.state.adminData}
            //pages={pages} // Display the total number of pages
            //loading={loading} // Display the loading overlay when we need it
            //onFetchData={this.adminData} // Request new data when things change
            filterable
            noDataText={<div className="loader" />}
            defaultPageSize={10}
            className="-striped -highlight"
          />
        </div>
      </div>
    );
  }
}

export default Modifyusers;
