import React from "react";

import MyTheme from "../common/Theme";

import Formservice from "../../services/Formservice";

import { Helmet } from "react-helmet";
import AOS from "aos"; // Animate on scroll
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";

import { MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import ReCAPTCHA from "react-google-recaptcha";

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class Careers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      fullNameError: "",
      email: "",
      errorError: "",
      phoneNumber: "",
      phoneNumberError: "",
      jobPosition: "",
      textmask: "(1  )    -    ",

      // File Upload Properties
      fileName: "",
      fileSize: "",
      fileType: "",
      filePayload: "",

      captchaVerify: false,
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange = (e, string) => {
    this.setState({
      [e.target.name]: event.target.value,
      [string]: event.target.value,
    });
  };

  captchaVerify = (value) => {
    const capVerify = value;
    if (capVerify !== "") {
      this.setState({
        captchaVerify: true,
      });
    }
    //console.log(this.state);
  };

  uploadImage = async (e) => {
    let file = e.target.files[0];
    var dataTypeURL = new FileReader();
    var arrayBuffer = new FileReader();

    await this.setState({
      fileName: file.name,
      fileType: file.type,
      fileSize: file.size,
    });

    dataTypeURL.onload = (e) => {
      this.setState({
        filePayload: e.target.result,
      });

      console.log(this.state);
    };

    dataTypeURL.readAsDataURL(file);

    //console.log(file);
  };

  validateForm = () => {
    let isError = false;
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    //let phoneRegex
    const errors = {
      fullNameError: "",
      emailError: "",
      phoneNumberError: "",
      jobPositionError: "",
      fileNameError: "",
      captchaError: "",
    };

    if (this.state.fullName === "") {
      isError = true;
      errors.fullNameError = "Please enter your full name";
    }

    if (this.state.email.indexOf("@") === -1) {
      isError = true;
      errors.emailError = "Please enter a valid email address";
    }

    if (this.state.jobPosition === "") {
      isError = true;
      errors.jobPositionError = "Please enter your desired job position";
    }

    if (this.state.fileNameError === "") {
      isError = true;
      errors.fileNameError = "Upload a file for us to review";
    }

    if (phoneRegex.test(this.state.phoneNumber) === false) {
      isError = true;
      errors.phoneNumberError = "Please enter a valid phone number";
    }

    if (this.state.captchaVerify === false) {
      isError = true;
      errors.captchaError = "* Please verify you're not a robot";
    }

    this.setState({
      ...this.state,
      ...errors,
      isError,
    });

    return isError;
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const err = this.validateForm();

    console.log(this.state);

    if (!err) {
      // clear the form
      this.setState({
        fullName: "",
        fullNameError: "",
        email: "",
        emailError: "",
        fileNameError: "",
        jobPosition: "",
        jobPositionError: "",
        phoneNumber: "",
        phoneNumberError: "",
        textmask: "(1  )    -    ",
      });

      const successData = {
        fullName: this.state.fullName,
        email: this.state.email,
        jobPosition: this.state.jobPosition,
        phoneNumber: this.state.phoneNumber,
        fileName: this.state.fileName,
        filePayload: this.state.filePayload,
      };

      const careerData = await Formservice.careerAuth(successData);

      alert(
        "Thank you. Your message has been successfully sent. We'll get back to you as soon as we can."
      );
    }
  };

  componentDidMount() {
    AOS.init();
  }

  render() {
    const { textmask } = this.state;
    return (
      <div className="careersContainer">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Mountain Manor Careers Page</title>
          <meta
            name="description"
            content="Mountain Manor Careers and Job Openings"
          />
          <link
            rel="canonical"
            href="https://mountainmanorskillednursing.com/careers"
          />
        </Helmet>
        <div className="fluid-container">
          <div className="bgimage">
            <div className="container">
              <div className="row justify-content-md-center">
                <div className="col-md-6 col-md-auto">
                  <div
                    className="bgContent"
                    style={{
                      background: "#000",
                      borderRadius: "25px",
                      opacity: 0.8,
                    }}
                  >
                    <div data-aos="fade-down" data-aos-once="true">
                      <h1 className="text-center">Join Our Team</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="formContainer">
          <div className="container">
            <div className="row justify-content-center">
              <h1 className="py-5 text-center">
                Mountain Manor
                <br /> Career Opportunities
              </h1>
              <iframe
                className="embed-responsive-item"
                src="https://mountainmanor.easyapply.co/"
                frameBorder="0"
                scrolling="yes"
                allowtransparency="yes"
                width="100%"
                height="750px"
              ></iframe>
            </div>
            <div className="careerformContainer shadow-lg">
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <p className="text-center">
                    Do you have an interest in helping others out? Are you
                    passionate about what you do to make an impact on the health
                    care industry? Welcome to Mountain Manor! Just fill out the
                    information below and we'll get back to you as soon as
                    possible.
                  </p>
                </div>
              </div>

              <MuiThemeProvider theme={MyTheme}>
                <form
                  method="POST"
                  noValidate
                  autoComplete="off"
                  onSubmit={(e) => this.onSubmit(e)}
                >
                  <div>
                    <Grid container spacing={24}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          name="fullName"
                          value={this.state.fullName}
                          type="text"
                          label="Full Name"
                          error={this.state.fullNameError ? true : false}
                          helperText={this.state.fullNameError}
                          variant="outlined"
                          onChange={(e) => this.onChange(e)}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          name="email"
                          type="email"
                          value={this.state.email}
                          label="Email Address"
                          error={this.state.emailError ? true : false}
                          helperText={this.state.emailError}
                          variant="outlined"
                          onChange={(e) => this.onChange(e)}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <FormControl>
                          <InputLabel
                            htmlFor="formatted-text-mask-input"
                            style={
                              this.state.phoneNumberError
                                ? { color: "red" }
                                : null
                            }
                          >
                            {this.state.phoneNumberError
                              ? this.state.phoneNumberError
                              : "Phone Number"}
                          </InputLabel>
                          <Input
                            type="tel"
                            value={textmask}
                            name="phoneNumber"
                            onChange={(e) => this.onChange(e, "textmask")}
                            inputComponent={TextMaskCustom}
                            error={this.state.phoneNumberError ? true : false}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          multiline
                          rows="5"
                          name="jobPosition"
                          value={this.state.jobPosition}
                          label="Desired Job Position"
                          error={this.state.jobPositionError ? true : false}
                          helperText={this.state.jobPositionError}
                          variant="outlined"
                          onChange={(e) => this.onChange(e)}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      justify="center"
                      direction="column"
                    >
                      <Grid item xs={12}>
                        <Input
                          style={{ display: "none" }}
                          accept="image/*"
                          id="outlined-button-file"
                          error={this.state.fileNameError ? true : false}
                          //multiple
                          type="file"
                          onChange={(e) => this.uploadImage(e)}
                          inputprops={{
                            startAdornment: <InputAdornment position="start" />,
                          }}
                        />
                        <InputLabel htmlFor="outlined-button-file">
                          {this.state.fileNameError ? (
                            <p style={{ color: "red" }}>
                              {this.state.fileNameError}
                            </p>
                          ) : null}
                          <Button variant="outlined" component="span">
                            <i className="fas fa-upload" />
                            UPLOAD RESUME CV
                          </Button>
                        </InputLabel>
                        {this.state.fileName !== "" ? (
                          <p style={{ color: "green" }}>
                            {"Uploaded file: " + this.state.fileName}
                          </p>
                        ) : null}
                      </Grid>
                      <Grid item xs={12}>
                        <ReCAPTCHA
                          sitekey={process.env.RECAPTCHA_API_KEY}
                          onChange={this.captchaVerify}
                        />
                        {this.state.captchaVerify === false ? (
                          <p style={{ textAlign: "left", color: "red" }}>
                            {this.state.captchaError}
                          </p>
                        ) : null}
                      </Grid>

                      <Grid item xs={12}>
                        <Button type="submit" variant="outlined">
                          SUBMIT
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </form>
              </MuiThemeProvider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Careers;
