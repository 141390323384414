import serviceBase from "./serviceBase";

const adminService = {
  adminselectUsers: data => serviceBase.post("/api/adminselectUsers", data),
  admineditUser: data => serviceBase.post("/api/admineditUser", data),
  adminaddUser: data => serviceBase.post("/api/adminaddUser", data),
  admindeleteUser: data => serviceBase.post("/api/admindeleteUser", data)
};

export default adminService;
