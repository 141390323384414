import React, { useEffect, useState } from "react";

import { Modal, Button } from "react-bootstrap";

const AppointmentConfirmationModal = (props) => {
  const { showModal, callbackModal } = props;

  const [modalState, setModal] = useState(showModal);

  const closeModal = () => {
    // setModal(false);
    callbackModal();
  };

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header style={{ backgroundColor: "#12C06A" }}>
        <div className="success text-success text-center m-auto">
          <Modal.Title>
            <img
              src="/images/successfulcheck.gif"
              style={{ width: "180px" }}
              alt="Success"
            />
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body style={{ textAlign: "center" }}>
        <h3 className="font-weight-bold py-3" style={{ color: "#000" }}>
          Appointment Scheduled
        </h3>
        <p className="pt-3" style={{ fontSize: "18px", lineHeight: "1.4em" }}>
          Your Appointment has been scheduled. Please check your email for a
          confirmation. If you don't see an email in your inbox, Please check
          your spam folder.
        </p>
        <p className="pt-3" style={{ fontSize: "18px", lineHeight: "1.4em" }}>
          If you have any questions about your appointment, please call{" "}
          <a href="tel:9169001499">(916) 900-1499</a>.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          className="m-auto"
          style={{ color: "#000" }}
          onClick={closeModal}
        >
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AppointmentConfirmationModal;
