import React from "react";

const Assistedliving = () => {
  return (
    <div className="assistedContainer">
      <div className="fluid-container">
        <div
          className="container"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="500"
          data-aos-once="true"
        >
          <div className="assisted-living-container">
            <h2 className="text-center font-weight-bold">Assisted Living</h2>

            <div className="row p-5">
              <p className="py-3">
                Welcome to Mountain Manor Assisted Living, where we strive to
                ensure that the warmth of home is ever-present. We take pride in
                establishing the groundwork for your comfort and independence,
                offering not only the essentials but also those thoughtful
                touches that bring peace of mind.
              </p>
              <p className="py-3">
                With our in-house skilled nursing facility, residents can
                maintain continuity in their familiar surroundings. Should the
                need arise for rehabilitation due to illness, we provide a
                seamless transition, allowing individuals to return to their
                assisted living rooms with ease.
              </p>
              <h3 className="py-3 text-dark">
                Assisted Living Signature Services:
              </h3>
            </div>
            <div className="infoList">
              <h3>Signature Services:</h3>
              <div className="row">
                <div className="col-sm-12 my-3 col-md-3 col-lg-2">
                  <div className="item text-center">
                    <i className="fas fa-3x fa-clock" />
                    <p className="pt-3">24-hour Staffing</p>
                  </div>
                </div>
                <div className="col-sm-12 my-3 col-md-3 col-lg-2">
                  <div className="item text-center">
                    <i className="fas fa-3x fa-running" />
                    <p className="pt-3">Daily Activities</p>
                  </div>
                </div>
                <div className="col-sm-12 my-3 col-md-3 col-lg-2">
                  <div className="item text-center">
                    <i className="fas fa-3x fa-prescription" />
                    <p className="pt-3">Medication Management</p>
                  </div>
                </div>
                <div className="col-sm-12 my-3 col-md-3 col-lg-2">
                  <div className="item text-center">
                    <i className="fas fa-3x fa-utensils" />
                    <p className="pt-3">Dietitian Approved Meals</p>
                  </div>
                </div>
                <div className="col-sm-12 my-3 col-md-3 col-lg-2">
                  <div className="item text-center">
                    <i className="fas fa-3x fa-tshirt" />
                    <p className="pt-3">Laundry Service</p>
                  </div>
                </div>
                <div className="col-sm-12 my-3 col-md-3 col-lg-2">
                  <div className="item text-center">
                    <i className="fas fa-3x fa-user-nurse" />
                    <p className="pt-3">Daily Housekeeping Service</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assistedliving;
