import React, { useState, useEffect } from "react";

import MyTheme from "../common/Theme";

import ImportantInfo from "./ImportantInfo";
import AppointmentConfirmationModal from "./AppointmentConfirmationModal";
import Button from "react-bootstrap/Button";
import { withStyles } from "@material-ui/core/styles";
import AOS from "aos"; // Animate on scroll

import MaskedInput from "react-text-mask";

import { MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Grid from "@material-ui/core/Grid";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import Scheduleservice from "../../services/Scheduleservice";
import { Helmet } from "react-helmet";
import Moment from "moment";

import { Link } from "react-router-dom";

// full calendar api dependencies
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

const styles = (theme) => ({
  root: {
    width: "90%",
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

// Time Slots

let timeSlots = [
  {
    id: 1,
    dayType: "Morning",
    times: [
      {
        Time: "T17:00:00.000Z",
      },
      {
        Time: "T18:00:00.000Z",
      },
      {
        Time: "T19:00:00.000Z",
      },
    ],
  },
  {
    id: 2,
    dayType: "Afternoon",
    times: [
      {
        Time: "T21:00:00.000Z",
      },
      {
        Time: "T22:00:00.000Z",
      },
      {
        Time: "T23:00:00.000Z",
      },
    ],
  },
  {
    id: 3,
    dayType: "Evening",
    times: [
      {
        Time: "T24:00:00.000Z",
      },
      {
        Time: "T02:00:00.000Z",
      },
      {
        Time: "T03:00:00.000Z",
      },
      // {
      //   Time: "T04:00:00.000Z",
      // },
    ],
  },
];

var today = new Date().toISOString().slice(0, 10);

const Voipcall = (props) => {
  const [scheduleData, setscheduleData] = useState({
    appointmentData: [],
    date: "",
    actualDateStart: "",
    actualDateEnd: "",
    startTime: "",
    endTime: "",
    subject: "",
    fullName: "",
    resident: "",
    communicationMethod: "In Person",
    email: "",
    phone: "(  )    -    ",
    description: "",
  });
  const [scheduleDataError, setscheduleDataError] = useState({
    subjectError: "",
    fullNameError: "",
    residentError: "",
    communicationMethodError: "",
    emailError: "",
    phoneError: "",
    descriptionError: "",
  });
  const [appointmentData, setAppointmentData] = useState([]);
  const [appointmentAvailability, setAppointmentAvailability] = useState([]);
  const [show, setShow] = useState(false);
  const [activeStep, setactiveStep] = useState(0);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div className="col-lg-8">
            <FullCalendar
              headerToolbar={{
                left: "title",
                center: null,
                right: "prev,next today",
              }}
              dayMaxEvents={true}
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              dateClick={handleDateClick}
              selectable={true}
              validRange={{
                start: today,
              }}
            />
          </div>
        );
      case 1:
        return (
          <div
            className="col-lg-8 col-md-12 order-md-last text-center"
            id="time-blocks"
          >
            {!appointmentAvailability.length ? (
              <p style={{ fontWeight: "bold", color: "red" }}>
                There are no more appointments for this day
              </p>
            ) : null}
            {appointmentAvailability.map((data, i) => (
              <div className="timeslot-container mt-2" key={i}>
                <Button
                  variant="outline-primary"
                  size="lg"
                  style={{ width: "150px" }}
                  onClick={(e) => selectAppointment(e, data)}
                  value={Moment(scheduleData.date + data).format("h:mm a")}
                >
                  {Moment(scheduleData.date + data).format("h:mm a")}
                </Button>
              </div>
            ))}
          </div>
        );
      case 2:
        return (
          <div className="form-container">
            <MuiThemeProvider theme={MyTheme}>
              <div className="formContainer">
                <p className="p-3 text-center">
                  <strong>Fields marked in * are required fields</strong>
                </p>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      name="subject"
                      value={scheduleData.subject}
                      type="text"
                      id="standard-required"
                      label="Appointment Subject *"
                      placeholder="Appointment Subject"
                      error={scheduleDataError.subjectError ? true : false}
                      helperText={scheduleDataError.subjectError}
                      variant="outlined"
                      onChange={(e) => onChange(e)}
                      onBlur={formErrors}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      name="fullName"
                      value={scheduleData.fullName}
                      type="text"
                      id="standard-required"
                      label="Full Name *"
                      placeholder="Full Name"
                      error={scheduleDataError.fullNameError ? true : false}
                      helperText={scheduleDataError.fullNameError}
                      variant="outlined"
                      onChange={(e) => onChange(e)}
                      onBlur={formErrors}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      name="resident"
                      value={scheduleData.resident}
                      type="text"
                      id="standard-required"
                      label="Resident Requesting to
                        Speak to *"
                      placeholder="Ex: John Doe"
                      error={scheduleDataError.residentError ? true : false}
                      helperText={scheduleDataError.residentError}
                      variant="outlined"
                      onChange={(e) => onChange(e)}
                      onBlur={formErrors}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="filled-select-currency-native"
                      select
                      label="Communication Method *"
                      name="communicationMethod"
                      onChange={(e) => onChange(e)}
                      SelectProps={{
                        native: true,
                      }}
                      // helperText="Please select an option"
                    >
                      <option value="In Person">In Person</option>
                      <option value="Zoom">Zoom</option>
                      <option value="Skype">Skype</option>
                      <option value="Facetime">Facetime</option>
                      <option value="Viber">Viber</option>
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      name="email"
                      value={scheduleData.email}
                      type="email"
                      id="standard-required"
                      label="Email *"
                      placeholder="Email Address"
                      error={scheduleDataError.emailError ? true : false}
                      helperText={scheduleDataError.emailError}
                      variant="outlined"
                      onChange={(e) => onChange(e)}
                      onBlur={formErrors}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <InputLabel
                        htmlFor="formatted-text-mask-input"
                        style={
                          scheduleDataError.phoneError ? { color: "red" } : null
                        }
                      >
                        {scheduleDataError.phoneError
                          ? scheduleDataError.phoneError
                          : "Phone *"}
                      </InputLabel>
                      <Input
                        type="tel"
                        value={scheduleData.phone}
                        id="standard-required"
                        name="phone"
                        onChange={(e) => onChange(e, "textmask")}
                        inputComponent={TextMaskCustom}
                        onBlur={formErrors}
                        error={scheduleDataError.phoneError ? true : false}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputLabelProps={{ shrink: true }}
                      multiline
                      rows="5"
                      name="description"
                      id="standard-required"
                      value={scheduleData.description}
                      label="Reason for Visit"
                      placeholder="What is the reason for your visit?"
                      variant="outlined"
                      onChange={(e) => onChange(e)}
                    />
                  </Grid>
                </Grid>
              </div>
            </MuiThemeProvider>
          </div>
        );
      default:
        return (
          <div className="appointment-preview p-3">
            {scheduleData.subject === "" ||
            scheduleData.fullName === "" ||
            scheduleData.resident === "" ||
            scheduleData.email === "" ||
            scheduleData.phone === "" ? (
              <div
                className="errorDiv text-center"
                style={{
                  backgroundColor: "#180605",
                  color: "rgb(250, 179, 174)",
                  padding: "0 20px",
                }}
              >
                <i className="fas fa-exclamation-circle fa-2x p-3"></i>
                <span style={{ verticalAlign: "text-top" }}>
                  You are missing required fields. please go back and correct
                  them.
                </span>
              </div>
            ) : (
              <h3 className="pb-3" style={{ color: "#70A768" }}>
                <strong>Does this information look right to you?</strong>
              </h3>
            )}
            <ul className="preview-info">
              <li>
                <strong>Appointment Date: </strong>
                {Moment(scheduleData.date).format("MM/DD/YYYY")}
              </li>
              <li>
                <strong>Appointment Time: </strong>
                {scheduleData.startTime} - {scheduleData.endTime}
              </li>
              <li>
                <strong>Appointment Subject: </strong>
                {scheduleData.subject}
              </li>
              <li>
                <strong>Full Name: </strong>
                {scheduleData.fullName}
              </li>
              <li>
                <strong>Resident Requesting to see: </strong>
                {scheduleData.resident}
              </li>
              <li>
                <strong>Preferred Method of Communication: </strong>{" "}
                {scheduleData.communicationMethod}
              </li>
              <li>
                <strong>Email Address: </strong>
                {scheduleData.email}
              </li>
              <li>
                <strong>Phone Number: </strong>
                {scheduleData.phone}
              </li>
              <li>
                <strong>Reason for Visit: </strong>
                {scheduleData.description}
              </li>
            </ul>
            <div className="schedule-button mt-4">
              <Button
                size="lg"
                variant="primary"
                style={{
                  margin: "0 5px",
                  color: "#000",
                  width: "100px",
                  fontSize: "18px",
                }}
                id="uniqueButton"
                onClick={prev}
              >
                Back
              </Button>

              <Button
                size="lg"
                variant="primary"
                style={{
                  margin: "0 5px",
                  color: "#000",
                  fontSize: "18px",
                }}
                id="uniqueButton"
                onClick={scheduleAppointment}
                disabled={
                  scheduleData.startTime === "" ||
                  scheduleData.subject === "" ||
                  scheduleData.fullName === "" ||
                  scheduleData.resident === "" ||
                  scheduleData.email === "" ||
                  scheduleData.phone === ""
                    ? true
                    : false
                }
              >
                Yes Schedule Appointment
              </Button>
            </div>
          </div>
        );
    }
  };

  const closeModal = () => {
    setShow(false);
  };

  const handleDateClick = async (arg) => {
    let newDate = arg.dateStr;

    // console.log("newDate", newDate);

    // Select available times in database
    const selectDate = await Scheduleservice.selectSchedule({
      date: newDate,
    });

    // console.log("test..", newDate);
    // console.log("test.. db query results get returned", selectDate);

    setscheduleData({
      ...scheduleData,
      date: newDate,
      appointmentData: selectDate,
    });

    // console.log("date is..", scheduleData.date);
  };

  const checkAvailability = () => {
    let newArr1 = [];
    let newArr2 = [];

    for (let i = 0; i < scheduleData.appointmentData.length; i++) {
      newArr1.push(scheduleData.appointmentData[i].SelectedTime);
      // console.log("test...", scheduleData.appointmentData[i].SelectedTime);
    }

    for (let j = 0; j < timeSlots.length; j++) {
      // console.log(timeSlots[j].times);
      for (let k = 0; k < timeSlots[j].times.length; k++) {
        // console.log(timeSlots[j].times[k].Time);
        newArr2.push(timeSlots[j].times[k].Time);
      }
    }

    // DB time Available array
    // console.log("arr1", scheduleData);
    // Hard coded array
    // console.log("arr2", newArr2);

    if (activeStep === 1) {
      // remove any appointment duplicates from hard coded timeslots to appointments for given day in the database

      let availability = newArr2.filter((items) => !newArr1.includes(items));

      setAppointmentAvailability(availability);

      // console.log("availability...", availability);
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });

    // Compare hard coded dates with dates in database for appointment availability
    checkAvailability();

    //console.log("new state is..", scheduleData);
  }, [scheduleData, activeStep]);

  const getSteps = () => {
    return [
      "Select a Date",
      "Select a Time",
      "Appointment Details",
      "Preview Information",
    ];
  };

  const next = () => {
    setactiveStep(activeStep + 1);
  };

  const prev = () => {
    setactiveStep(activeStep - 1);
  };

  const selectAppointment = (e, rawTime) => {
    let formatTime = Moment(scheduleData.date + rawTime)
      .add(1, "hours")
      .format("h:mm a");

    let formatDate = scheduleData.date + rawTime;

    setscheduleData({
      ...scheduleData,
      startTime: e.target.value,
      endTime: formatTime,
      actualDateStart: formatDate,
      actualDateEnd: Moment(formatDate).add(1, "hours").toISOString(),
    });
  };

  // console.log("schedule data is..", scheduleData);

  const formErrors = () => {
    // Handle Form Errors
    let isError = false;
    const phoneRegex =
      /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;
    const formErrors = {
      subjectError: "",
      fullNameError: "",
      residentError: "",
      communicationMethodError: "",
      emailError: "",
      phoneError: "",
    };

    if (scheduleData.subject === "") {
      isError = true;
      formErrors.subjectError = "* Please enter an appointment subject";
    }

    if (scheduleData.fullName === "") {
      isError = true;
      formErrors.fullNameError = "* Please enter your full name";
    }

    if (scheduleData.resident === "") {
      isError = true;
      formErrors.residentError = "* Please enter a residents name";
    }

    if (scheduleData.email.indexOf("@") === -1) {
      isError = true;
      formErrors.emailError = "* Please enter a valid email address";
    }

    if (phoneRegex.test(scheduleData.phone) === false) {
      isError = true;
      formErrors.phoneError = "Please enter a valid phone number";
    }

    setscheduleDataError({
      ...scheduleDataError,
      ...formErrors,
      isError,
    });

    return isError;
  };

  const onChange = (e) => {
    setscheduleData({
      ...scheduleData,
      [e.target.name]: e.target.value,
    });
  };

  const scheduleAppointment = () => {
    // console.log("schedule data is.....", scheduleData);

    if (
      scheduleData.subject === "" ||
      scheduleData.fullName === "" ||
      scheduleData.resident === "" ||
      scheduleData.communicationMethod === "" ||
      scheduleData.email === "" ||
      scheduleData.phone === "" ||
      scheduleData.startTime === "" ||
      scheduleData.endTime === ""
    ) {
      // console.log("submission false");
      alert(
        "There was an error with your submission. Please fill out all required fields and try again."
      );
    } else {
      const str = scheduleData.actualDateStart;

      let formattedStr = str.split("T");

      let result = "T" + formattedStr[1];

      let insertData = {
        RoleId: undefined ? "3" : "3",
        date: scheduleData.date,
        Subject: scheduleData.subject,
        FullName: scheduleData.fullName,
        Resident: scheduleData.resident,
        CommunicationMethod: scheduleData.communicationMethod,
        Email: scheduleData.email,
        Phone: scheduleData.phone,
        SelectedTime: result,
        StartTime: scheduleData.actualDateStart,
        EndTime: scheduleData.actualDateEnd,
        Description:
          scheduleData.description === undefined
            ? null
            : scheduleData.description,
        DateCreated: Moment().format("YYYY-MM-DD hh:mm:ss"),
      };

      //console.log("insert data is..", insertData);

      const insertSchedule = Scheduleservice.insertSchedule(insertData);

      // Clear all data and fields
      setscheduleData({
        appointmentData: [],
        date: "",
        actualDateStart: "",
        actualDateEnd: "",
        startTime: "",
        endTime: "",
        subject: "",
        fullName: "",
        resident: "",
        communicationMethod: "In Person",
        email: "",
        phone: "(  )    -    ",
        description: "",
      });
      setscheduleDataError({
        subjectError: "",
        fullNameError: "",
        residentError: "",
        communicationMethodError: "",
        emailError: "",
        phoneError: "",
        descriptionError: "",
      });
      setactiveStep(0);
      setShow(true);
    }
  };

  const steps = getSteps();
  return (
    <div className="fluid-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mountain Manor Visitation Schedule Appointment</title>
        <meta
          name="description"
          content="Mountain Manor Visitation Schedule Appointment"
        />
        <link
          rel="canonical"
          href="https://mountainmanorskillednursing.com/voipCall"
        />
      </Helmet>

      <div className="voipcall-container">
        <div className="container">
          <h2 className="py-4 text-center">Schedule an Appointment</h2>
          <div className="fullcalendar-container shadow-lg p-3">
            <div
              className="appointmentHeader"
              style={{ backgroundColor: "#F9F6FC" }}
            >
              <Stepper
                activeStep={activeStep}
                style={{ backgroundColor: "#E0F2FF", marginBottom: "20px" }}
              >
                {steps.map((label) => {
                  const props = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...props}>
                      <StepLabel {...labelProps} alternativeLabel>
                        {label}
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </div>
            <div className="schedule-progress">
              <div className="row justify-content-center" data-aos="fade-up">
                {getStepContent(activeStep)}
                {activeStep < 3 ? (
                  <div
                    className="col-lg-4 col-md-12 order-first"
                    data-aos="fade-up"
                  >
                    <div className="preview d-flex h-100 border">
                      <div className="preview-content p-3">
                        <div className="preview-date">
                          <h2 className="pb-4">Appointment Preview</h2>
                          <p className="text-center pb-4">
                            <strong>Appointment Details: </strong>
                          </p>
                          <hr />
                          <p className="py-2">
                            <strong>Appointment Duration:</strong> 1 hour
                          </p>

                          <p className="py-2">
                            <strong>Appointment Date: </strong>

                            {scheduleData.date
                              ? Moment(scheduleData.date).format("MMMM Do YYYY")
                              : "---"}
                          </p>
                          <p className="py-2">
                            <strong>Appointment Time: </strong>{" "}
                            {scheduleData.startTime !== "" ||
                            scheduleData.endTime !== ""
                              ? scheduleData.startTime +
                                " - " +
                                scheduleData.endTime
                              : "---"}
                          </p>
                        </div>
                        <div className="preview-button text-center mt-3">
                          {activeStep > 0 ? (
                            <Button
                              size="lg"
                              variant="primary"
                              style={{
                                margin: "0 5px",
                                color: "#000",
                                width: "100px",
                                fontSize: "18px",
                              }}
                              id="uniqueButton"
                              onClick={prev}
                            >
                              Back
                            </Button>
                          ) : null}
                          <Button
                            size="lg"
                            variant="primary"
                            style={{
                              margin: "0 5px",
                              color: "#000",
                              width: "100px",
                              fontSize: "18px",
                            }}
                            id="uniqueButton"
                            onClick={next}
                            disabled={scheduleData.date === "" ? true : false}
                          >
                            Next
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* Confirmation Modal */}
          <AppointmentConfirmationModal
            showModal={show}
            callbackModal={closeModal}
          />

          <p className="p-3">
            For questions on how to use our scheduler please make sure to visit
            our <Link to="faq">FAQ page</Link> for the latest information.
          </p>
          {/* Important Info */}
          <ImportantInfo />
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Voipcall);
