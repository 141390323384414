import React from "react";

import AOS from "aos"; // Animate on scroll

const SkilledNursing = () => {
  AOS.init();
  return (
    <div className="skilled-nursing-container pb-5 my-5">
      <div className="services">
        <div className="fluid-container">
          <div
            className="container"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="500"
            data-aos-once="true"
          >
            <div className="h2Header">
              <h1 className="py-5" style={{ color: "black" }}>
                Explore Our Skilled Nursing
              </h1>
            </div>
            <div className="servicesContainer">
              <div className="row justify-content-center">
                <div className="col-sm-12 col-md-4">
                  <div className="image-container">
                    <img
                      className="rounded w-100"
                      style={{ height: "250px" }}
                      src="/images/personalized-care.webp"
                      alt="Rehabilitation Services"
                    />
                    <h3 className="text-dark text-center py-2">
                      Personalized Care
                    </h3>
                    <p className="py-3">
                      Our physicians and nurses work to design plans of care
                      based on your individual needs.
                    </p>
                  </div>
                </div>

                <div className="col-sm-12 col-md-4">
                  <div className="image-container">
                    <img
                      className="rounded w-100"
                      style={{ height: "250px" }}
                      src="/images/mmskillednursingcare.webp"
                      alt="Skilled Nursing Care Services"
                    />
                    <h3 className="text-dark text-center py-2">
                      Innovative Therapy Programs
                    </h3>
                    <p className="py-3">
                      Get the most out of your therapy time with Jintronix,
                      state-of-the art virtual reality therapy programs.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="image-container">
                    <img
                      className="rounded w-100"
                      style={{ height: "250px" }}
                      src="/images/mmrehabcover.webp"
                      alt="Facility Amenity Services"
                    />
                    <h3 className="text-dark text-center py-2">
                      Short-Term Post-Acute Care
                    </h3>
                    <p className="py-3">
                      Mountain Manor has grown to become a preferred destination
                      for anybody needing short term post-acute care. We are
                      committed to providing every resident with the best
                      experience in the region!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkilledNursing;
