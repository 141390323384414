import React, { useEffect, useState } from "react";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import Moment from "moment";

import AppController from "../../controllers/appController";
import Detailedservice from "../../services/Detailedservice";

const DetailedReport = () => {
  const [dateValues, handleDateChange] = useState({
    startDate: "",
    endDate: "",
  });
  const [emptyText, setEmpty] = useState(false);
  const [sucessText, setSuccess] = useState(false);
  const [loadingState, isLoading] = useState(false);
  const [scheduleData, setSchedule] = useState({
    startDate: "",
    endDate: "",
    bathData: [],
  });
  const [goodState, setGood] = useState(false);

  const handleChange = (e) => {
    handleDateChange({ ...dateValues, [e.target.name]: e.target.value });
  };

  const getSchedule = async () => {
    if (dateValues.startDate !== "" && dateValues.endDate !== "") {
      const data = {
        startDate: dateValues.startDate,
        endDate: dateValues.endDate,
      };

      console.log("date values", data);

      // Send startDate and endDate to backend
      const sendSchedule = await Detailedservice.getSchedule(data);

      console.log("send schedule data is....", sendSchedule);

      // If data is empty
      if (sendSchedule.length == 0) {
        setEmpty(true);
        setSuccess(false);
      } else {
        setEmpty(false);
      }

      // console.log("selected date data", sendSchedule);

      setSchedule(sendSchedule);
    }
  };

  const fetchSchedule = async () => {
    // Clean up the data
    dateValues.startDate = Moment(dateValues.startDate).format("MM/DD/YYYY");
    dateValues.endDate = Moment(dateValues.endDate).format("MM/DD/YYYY");

    scheduleData.forEach((data) => {
      data.CommunicationMethod =
        data.CommunicationMethod === null ? "---" : data.CommunicationMethod;
      data.Email = data.Email === null ? "---" : data.Email;
      data.FullName = data.FullName === null ? "---" : data.FullName;
      data.Phone = data.Phone === null ? "---" : data.Phone;
      data.Resident = data.Resident === null ? "---" : data.Resident;
      data.Reason = data.Reason === null ? "---" : data.Reason;
      data.StartTime =
        data.StartTime === null
          ? null
          : Moment(data.StartTime).format("MM/DD/YY h:mm a");
      data.EndTime =
        data.EndTime === null
          ? null
          : Moment(data.EndTime).format("MM/DD/YY h:mm a");
    });

    const data = {
      selectedDates: {
        startDate: dateValues.startDate,
        endDate: dateValues.endDate,
      },
      requestedData: scheduleData,
    };

    console.log("data is...", data);

    isLoading(true);
    setSuccess(true);

    const viewPdf = await Detailedservice.pdfTemplate(data);

    setGood(true);

    var link = document.createElement("a");
    // // Location of where the download file is located in
    link.href = AppController.downloadPDF() + "MM_Report.pdf";
    link.target = "_blank";
    // // Name of download file
    link.download = "MM_Report.pdf";
    link.dispatchEvent(new MouseEvent("click"));

    setTimeout(() => {
      isLoading(false);
    }, 1000);
  };

  useEffect(() => {
    getSchedule();
  }, [dateValues, emptyText, goodState]);

  return (
    <div className="detailedreportContainer">
      <div className="container">
        <div
          className="backgroundColor"
          style={{
            backgroundColor: "#E0F2FF",
            padding: "20px",
            height: "500px",
          }}
        >
          {emptyText ? (
            <div
              className="errorDiv text-center"
              style={{
                backgroundColor: "#180605",
                color: "rgb(250, 179, 174)",
              }}
            >
              <i className="fas fa-exclamation-circle fa-2x p-3"></i>
              <span>
                There is no data for the date range you selected. Please try
                another date range.
              </span>
            </div>
          ) : null}
          {sucessText ? (
            <div
              className="successDiv text-center"
              style={{
                backgroundColor: "rgb(7,17,7)",
                color: "rgb(76, 175, 80)",
              }}
            >
              <i className="far fa-check-circle fa-2x p-3"></i>
              <span>
                Your PDF has successfully downloaded. Please check your
                downloads folder.
              </span>
            </div>
          ) : null}
          <div className="row d-flex justify-content-center">
            <div className="col-lg-2">
              <div className="daterange" style={{ marginTop: "150px" }}>
                <div className="daterangeCenter">
                  <TextField
                    id="startDate"
                    label="Start Date"
                    name="startDate"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="daterange" style={{ marginTop: "150px" }}>
                <div className="daterangeCenter d-flex justify-content-center">
                  <TextField
                    id="endDate"
                    label="End Date"
                    name="endDate"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="row d-flex justify-content-center"
            style={{ marginTop: "20px" }}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={
                !dateValues.startDate || !dateValues.endDate || emptyText
                  ? true
                  : false
              }
              onClick={fetchSchedule}
            >
              {loadingState ? (
                <i
                  className={"fas fa-circle-notch fa-spin"}
                  style={{ fontSize: "24px" }}
                />
              ) : (
                "Generate Report"
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailedReport;
