import React from "react";

import AOS from "aos"; // Animate on scroll
import { Helmet } from "react-helmet";

import UserReviews from "./UserReviews";

const Testimonials = () => {
  AOS.init({
    duration: 2000,
  });
  AOS.refresh();

  return (
    <div className="testimonailsContainer">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mountain Manor Testimonials</title>
        <meta
          name="description"
          content="Mountain Manor has reviews from how people feel about the facility."
        />
        <link
          rel="canonical"
          href="https://www.mountainmanorskillednursing.com/testimonials"
        />
      </Helmet>

      <div className="testimonials">
        <div className="container">
          <h1 className="py-3 col-md-12 col-xs-12 text-md-left text-center">
            Testimonials
          </h1>
          <div className="nursingcareTreatment">
            <div className="container">
              <UserReviews />
            </div>
          </div>

          <div className="reviews">
            <h1 className="py-3 col-md-12 col-xs-12 text-md-left text-center">
              How is your experience?
            </h1>

            <h2 className="pb-3 col-md-12 col-xs-12 text-md-left text-center">
              Leave us a review!
            </h2>
            <div className="row justify-content-center">
              {/* <div className="col-md-3 shadow-sm p-3 m-2">
                <a
                  href="https://www.yelp.com/biz/mountain-manor-carmichael-3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="card m-3 text-center">
                    <h4 className="card-title pt-3">
                      <strong>Yelp</strong>
                    </h4>
                    <div className="card-body">
                      <i className="fab fa-yelp fa-4x"></i>
                    </div>
                  </div>
                </a>
              </div> */}
              <div className="col-md-4 shadow-sm p-3 m-2">
                <a
                  href="https://www.facebook.com/Mountain-Manor-Skilled-Nursing-Assisted-Living-583759022110956/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="card m-3 text-center">
                    <h4 className="card-title pt-3">
                      <strong>Facebook</strong>
                    </h4>
                    <div className="card-body">
                      <i className="fab fa-facebook-square fa-4x"></i>
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-md-4 shadow-sm p-3 m-2">
                <a
                  href="https://www.google.com/maps/place/Mountain+Manor/@38.6119554,-121.3312113,17z/data=!4m7!3m6!1s0x809adc41b065bccd:0xab8a86e9cc70c2d!8m2!3d38.6119512!4d-121.3290173!9m1!1b1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="card m-3 text-center">
                    <h4 className="card-title pt-3">
                      <strong>Google</strong>
                    </h4>
                    <div className="card-body">
                      <i className="fab fa-google fa-4x"></i>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
