import React, { useState } from 'react';
import Rating from 'react-rating';

import MyTheme from "../common/Theme";
import { Helmet } from "react-helmet";
import AOS from "aos";
import { MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Formservice from "../../services/Formservice";

const Feedback = () => {
    AOS.init();
    const hasError = (fieldName) => !!errors[fieldName]; // Function to check if a field has an error
    const [isLoading, setIsLoading] = useState(false);

    const [formData, setFormData] = useState({
        rating: 0,
        fullName: "",
        message: "",
    });

    const [errors, setErrors] = useState({
        rating: "",
        fullName: "",
        message: "",
    });

    const resetForm = () => {
        setFormData({
            rating: 0,
            fullName: "",
            message: "",
        });
        setErrors({
            rating: "",
            fullName: "",
            message: "",
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };

    const handleRatingChange = (value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            rating: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            rating: "",
        }));
    };


    const validateForm = () => {
        const newErrors = {};

        if (formData.rating === 0) {
            newErrors.rating = "Rating is required.";
        }

        if (!formData.fullName.trim()) {
            newErrors.fullName = "Full Name is required.";
        }

        if (!formData.message.trim()) {
            newErrors.message = "Message is required.";
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isFormValid = validateForm();

        if (isFormValid) {
            setIsLoading(true); // Start showing the loading indicator

            // Handle form submission here
            console.log('Form data:', formData);

            try {
                // Submit the api call
                const feedbackData = await Formservice.feedbackAuth(formData);

                alert("Thank you. Your feedback has been received!");

                // Reset the form after successful submission
                resetForm();
            } catch (error) {
                alert("Error: Unable to submit feedback. Please try again later.");
            }

            setIsLoading(false);
        };
    }

    return (
        <React.Fragment>
            <div className="feedback-container d-flex justify-content-center">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Mountain Manor Feedback Page</title>
                    <meta name="description" content="Mountain Manor Feedback | Tell us about your experience at our facility" />
                    <link
                        rel="canonical"
                        href="https://mountainmanorskillednursing.com/feedback"
                    />
                </Helmet>
                <div className="formContainer w-50 p-2" data-aos="fade-down">
                    <h2 className='text-center py-5'><strong>We would love to hear your feedback!</strong></h2>
                    <MuiThemeProvider theme={MyTheme}>
                        <Grid>
                            <form
                                method="POST"
                                noValidate
                                autoComplete="off"
                                onSubmit={handleSubmit}
                            >
                                <p className='py-3'><strong>Overall Satisfaction</strong></p>
                                <Rating
                                    emptySymbol={<i className="far fa-star fa-3x" style={{ color: 'gold', padding: '5px' }} />}
                                    fullSymbol={<i className="fas fa-star fa-3x" style={{ color: 'gold', padding: '5px' }} />}
                                    initialRating={formData.rating}
                                    onChange={(value) => handleRatingChange(value)}
                                />
                                {errors.rating && <p className="text-danger">{errors.rating}</p>}
                                <p className='py-3'><strong>Your Full Name</strong></p>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    type="text"
                                    name="fullName"
                                    label="Your Name"
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    value={formData.fullName}
                                    onChange={handleInputChange}
                                    error={hasError("fullName")}
                                    helperText={hasError("fullName") ? errors.fullName : ""}
                                />
                                <p className='py-3'><strong>Tell us about your overall experience at our facility</strong></p>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    multiline
                                    rows="5"
                                    name="message"
                                    label="Message"
                                    variant="outlined"
                                    value={formData.message}
                                    onChange={handleInputChange}
                                    error={hasError("message")}
                                    helperText={hasError("message") ? errors.message : ""}
                                />
                                {isLoading ? (
                                    <Button variant="outlined">
                                        <i className="fas fa-spinner" style={{
                                            animation: "spin 1s linear infinite",
                                            "@keyframes spin": {
                                                "0%": {
                                                    transform: "rotate(360deg)",
                                                },
                                                "100%": {
                                                    transform: "rotate(0deg)",
                                                },
                                            },
                                            fontSize: '25px',
                                            color: '#fff'
                                        }} />
                                    </Button>

                                ) : (
                                    <Button variant="outlined" type="submit">
                                        SUBMIT
                                    </Button>
                                )}
                            </form>
                        </Grid>
                    </MuiThemeProvider>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Feedback;
