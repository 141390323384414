import React from "react";

import { Link as RouterLink, withRouter } from "react-router-dom";

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";

import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import BusinessIcon from "@material-ui/icons/Business";
import WorkIcon from "@material-ui/icons/Work";
import StarsIcon from "@material-ui/icons/Stars";
import CallIcon from "@material-ui/icons/Call";
import RssFeedIcon from "@material-ui/icons/RssFeed";
import HomeIcon from "@material-ui/icons/Home";
import VideocamIcon from "@material-ui/icons/Videocam";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";

const styles = {
  list: {
    width: 350,
  },
  fullList: {
    width: "auto",
  },
  listItemText: {
    color: "black",
    fontFamily: "Noto Serif SC, serif",
    fontWeight: "700",
  },
};

class SideBar extends React.Component {
  state = {
    left: false,
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  handleNavigation = (path) => {
    const { history } = this.props;
    history.push(path);
  };

  componentDidMount = () => {
    document.addEventListener("click", this.handleClickOutside, true);
  };

  componentWillUnmount = () => {
    document.removeEventListener("click", this.handleClickOutside, true);
  };

  render() {
    const { classes, primary, secondary } = this.props;

    const sideList = (
      <div className={classes.list}>
        <List>
          {[
            { text: "Home", icon: <HomeIcon />, path: "/" },
            { text: "Videos", icon: <VideocamIcon />, path: "/videos" },
            {
              text: "Services",
              icon: <LocalHospitalIcon />,
              path: "/services",
            },
            { text: "Blog", icon: <RssFeedIcon />, path: "/Blog" },
            { text: "About Us", icon: <BusinessIcon />, path: "/aboutUs" },
            { text: "Contact Us", icon: <CallIcon />, path: "/contactUs" },
          ].map((item, index) => (
            <ListItem
              button
              key={item.text}
              onClick={() => this.handleNavigation(item.path)}
            >
              <ListItemIcon style={{ color: "#06A7D3" }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography className={classes.listItemText}>
                    {item.text.toUpperCase()}
                  </Typography>
                }
                secondary={secondary}
              />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {[
            {
              text: "Testimonials",
              icon: <StarsIcon />,
              path: "/testimonials",
            },
            { text: "Careers", icon: <WorkIcon />, path: "/careers" },
          ].map((item, index) => (
            <ListItem
              button
              key={item.text}
              onClick={() => this.handleNavigation(item.path)}
            >
              <ListItemIcon style={{ color: "#06A7D3" }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography className={classes.listItemText}>
                    {item.text.toUpperCase()}
                  </Typography>
                }
                secondary={secondary}
              />
            </ListItem>
          ))}
        </List>
      </div>
    );

    return (
      <div className="sidebar-container">
        <MenuIcon
          onClick={this.toggleDrawer("left", true)}
          style={{ cursor: "pointer", fontSize: "40px" }}
        />

        <Drawer
          open={this.state.left}
          onClose={this.toggleDrawer("left", false)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer("left", false)}
            onKeyDown={this.toggleDrawer("left", false)}
          >
            <div className="logo text-center pt-3">
              <RouterLink to="/">
                <img
                  src="/images/mmlogomain.jpg"
                  alt="Mountain Manor Logo"
                  width="200px"
                  height="116px"
                />
              </RouterLink>
            </div>
            <Divider style={{ marginTop: "20px" }} />
            <div
              className="close-menu d-flex justify-content-end"
              style={{ position: "relative", top: "-150px", right: "15px" }}
              title="close menu"
            >
              <CloseIcon
                onClick={this.toggleDrawer("left", false)}
                style={{
                  display: "flex",
                  fontSize: "30px",
                  cursor: "pointer",
                }}
              />
            </div>

            {sideList}
          </div>
        </Drawer>
      </div>
    );
  }
}

SideBar.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(SideBar));
