import React from "react";

class adminEditing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      isLoading: false,
    };
  }

  Editing = () => {
    this.setState({ isEditing: !this.state.isEditing });

    // Pass child state back to parent
    this.props.editBlog(true);
  };

  Cancel = () => {
    this.setState({ isEditing: false });

    this.props.cancelBlog(false);
  };

  Publish = () => {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({
        isLoading: false,
        isEditing: false,
      });
    }, 1000);

    this.props.publishBlog(false);
  };

  render() {
    //console.log(this.state.isEditing);
    return (
      <div className="editingContainer">
        {this.state.isEditing === true ? (
          <div className="container">
            <div className="editingHeader">
              <div className="row">
                <div className="col-md-4">
                  {this.state.isLoading ? (
                    <div className="loaderContainer">
                      <i className="fas fa-spinner" id="smloader" /> Please wait
                      ...!
                    </div>
                  ) : (
                    <ul>
                      <li onClick={(e) => this.Publish()}>Publish</li>
                      <li onClick={(e) => this.Cancel()}>Cancel</li>
                    </ul>
                  )}
                </div>
                <div className="col-md-6">
                  <p>Editing "{this.props.title.toUpperCase()}"...</p>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="admin-editing">
          <div className="edit-triangle" onClick={(e) => this.Editing()}>
            <div
              className={
                this.state.isEditing === false ? "triangle" : "triangle editing"
              }
            >
              <i className="fas fa-long-arrow-alt-down" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default adminEditing;
