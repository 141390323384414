import serviceBase from "./serviceBase";

const blogService = {
  selectblogTopics: data => serviceBase.post("/api/selectblogTopics", data),
  insertblogPost: data => serviceBase.post("/api/insertblogPost", data),
  editBlog: data => serviceBase.post("/api/editBlog", data),
  deleteBlog: data => serviceBase.post("/api/deleteBlog", data),
  selectblogArticle: data => serviceBase.post("/api/selectblogArticle", data),
  editArticle: data => serviceBase.post("/api/editArticle", data)
};

export default blogService;
