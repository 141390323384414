import { createMuiTheme } from "@material-ui/core/styles";

export default createMuiTheme({
  typography: {
    fontFamily: 'Noto Serif SC", serif',
    useNextVariants: true,
  },
  menu: {
    width: 200,
  },
  overrides: {
    input: {
      display: "none",
    },
    MuiFormControl: {
      root: {
        margin: "10px 0",
        width: "100%",
      },
    },
    MuiOutlinedInput: {
      multiline: {
        height: 100,
      },
    },
    MuiSelect: {
      root: {
        //width: '250px',
        display: "flex",
        border: "1px solid #ced4da",
        borderRadius: "3px",
      },
    },
    MuiInputBase: {
      root: {
        borderColor: "#000",
        height: 35,
        display: "flex",
      },
    },
    MuiButtonBase: {
      root: {
        background: "#06a7d3 !important",
        color: "#fff !important",
        borderRadius: "30px !important",
        border: "0px !important",
        height: "35px !important",
        margin: "10px !important",
        outline: "none !important",
        marginLeft: "10px !important",
        fontSize: "16px !important",
        "&:hover": {
          backgroundColor: "#06a7d3 !important",
          borderColor: "#06a7d3 !important",
          color: "#fff !important",
        },
      },
    },
    MuiPaper: {
      root: {
        overflow: "hidden",
      },
    },
  },
});
