import React from "react";

import { Link } from "react-router-dom";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    var today = new Date();
    return (
      <div className="footerContainer" style={{ marginTop: "50px" }}>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-md-center col-lg-6 text-lg-left">
                <img
                  width="200px"
                  height="116px"
                  src="/images/mmlogomain.jpg"
                  alt="Mountain Manor Logo"
                />
              </div>
              <div className="col-md-6 text-md-center col-lg-6 text-lg-right">
                <div className="socialfooterContainer">
                  <a
                    href="https://www.youtube.com/channel/UCLOkT572m8wxcZEw513EHKA/videos"
                    target="_blank"
                    alt="Mountain Manor YouTube"
                    rel="noopener noreferrer"
                    title="YouTube"
                  >
                    <i className="fab fa-youtube fa-2x" />
                  </a>
                  <a
                    href="https://www.facebook.com/Mountain-Manor-Skilled-Nursing-Assisted-Living-583759022110956/"
                    target="_blank"
                    alt="Mountain Manor Facebook"
                    rel="noopener noreferrer"
                    title="Facebook"
                  >
                    <i className="fab fa-facebook-f fa-2x" />
                  </a>
                  {/* <a
                    target="_blank"
                    href="https://www.yelp.com/biz/mountain-manor-carmichael-3"
                    alt="Mountain Manor Yelp"
                    rel="noopener noreferrer"
                    title="Yelp"
                  >
                    <i className="fab fa-yelp fa-2x" />
                  </a> */}
                </div>
              </div>
            </div>
            <hr className="my-3" />

            <div className="quickLinks">
              <div className="row">
                <div className="col-lg-4">
                  <h3>Relative Links</h3>
                  <ul>
                    <Link to="/services">
                      <li>Services</li>
                    </Link>
                    <Link to="/testimonials">
                      <li>Testimonials</li>
                    </Link>
                    <Link to="/accessibility">
                      <li>Accessibility</li>
                    </Link>
                    <Link to="/careers">
                      <li>Careers</li>
                    </Link>
                    <Link to="/contactUs">
                      <li>Contact Us</li>
                    </Link>
                  </ul>
                </div>
                <div className="col-lg-4">
                  <h3>Leave a Review</h3>
                  <div className="reviewInfo">
                    <a
                      href="https://www.google.com/maps/place/Mountain+Manor/@38.6119512,-121.331206,17z/data=!4m7!3m6!1s0x809adc41b065bccd:0xab8a86e9cc70c2d!8m2!3d38.6119512!4d-121.3290173!9m1!1b1"
                      target="_blank"
                      rel="noreferrer nooppener"
                      className="py-3"
                    >
                      <i className="fab fa-google fa-3x"></i>
                    </a>
                    {/* <a
                      href="https://www.yelp.com/biz/mountain-manor-carmichael-3"
                      target="_blank"
                      rel="noreferrer nooppener"
                      className="py-3"
                    >
                      <i className="fab fa-yelp fa-3x"></i>
                    </a> */}
                  </div>
                </div>
                <div className="col-lg-4">
                  <h3>Get in Contact</h3>
                  <div className="contactInfo">
                    <div className="info">
                      <i className="fas fa-map-marked">
                        <a
                          href="https://www.google.com/maps/place/Mountain+Manor/@38.6119512,-121.331206,17z/data=!3m1!4b1!4m5!3m4!1s0x809adc41b065bccd:0xab8a86e9cc70c2d!8m2!3d38.6119512!4d-121.3290173"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span>6101 Fair Oaks Blvd, Carmichael CA 95608</span>
                        </a>
                      </i>
                    </div>
                    <div className="info">
                      <p>
                        <i className="fas fa-phone" />

                        <a href="tel:9162960561">
                          Admissions Line: (916) 296-0561
                        </a>
                        <a href="tel:4152502721">
                          Administrator: (415) 250-2721
                        </a>
                        <a href="tel:9164887211">
                          Facility Line: (916) 488-7211
                        </a>
                      </p>
                    </div>
                    <div className="info">
                      <p>
                        <i className="fas fa-envelope" />
                        <a href="mailto:infomountainmanor@gmail.com">
                          infomountainmanor@gmail.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer">
              <footer>
                <div className="certifications text-center">
                  <img
                    src="/images/footermedicarecertified.jpg"
                    alt="Medicare Certified"
                    title="Department of Health &amp; Human Services Certified"
                    width="120px"
                    height="120px"
                  />
                  <img
                    src="/images/footercahf.jpg"
                    alt="Medicare Certified"
                    title="California Association of Health Services Certified"
                    width="120px"
                    height="120px"
                  />
                </div>
                <p className="py-2">
                  Copyright &copy; {today.getFullYear()} Mountain Manor Skilled
                  Nursing &amp; Assisted Living | All Rights Reserved.
                </p>
              </footer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
