import React from "react";

import ApexCharts from "apexcharts";
import Moment from "moment";

import { Link } from "react-router-dom";

import Detailedservice from "../../services/Detailedservice";

class Analytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      todayCount: "",
      yesterdayCount: "",
      lastDayCount: "",
      weekCount: [],
      formattedDates: [],
    };
  }

  selectToday = async () => {
    const today = await Detailedservice.todaySchedule();

    this.setState({
      todayCount: today[0]["COUNT(*)"],
    });

    // console.log("today data is..", this.state.todayCount);
  };

  selectWeek = async () => {
    const week = await Detailedservice.weekSchedule();

    // Format it Monday, Tuesday, Wednesday etc...
    let formattedDates = [];
    let formattedCounts = [];

    week.forEach((dates) => {
      // console.log("dates are", dates);
      let newDates = Moment(dates.date).format("MM/DD");

      formattedDates.push(newDates);
    });

    week.forEach((counts) => {
      formattedCounts.push(counts.count);
    });

    await this.setState({
      weekCount: formattedCounts,
      formattedDates: formattedDates,
    });

    // console.log("formatted week data is...", formatDates);
    // console.log("week data is...", week);
  };

  selectYesterday = async () => {
    const yesterday = await Detailedservice.yesterdaySchedule();

    this.setState({
      yesterdayCount: yesterday[0]["COUNT(*)"],
    });

    // console.log("yesterday data is..", this.state.yesterdayCount);
  };

  selectlastDays = async () => {
    const last = await Detailedservice.lastDays();

    this.setState({
      lastDayCount: last[0]["COUNT(*)"],
    });

    // console.log("last days data is..", last);
  };

  componentDidMount = async () => {
    // Check if admin is logged in

    await this.selectToday();
    await this.selectWeek();
    await this.selectYesterday();
    await this.selectlastDays();

    // console.log("state count is", this.state.weekCount);

    var lineOptions = {
      chart: {
        height: 300,
        width: "100%",
        type: "line",
      },
      series: [
        {
          name: "Appointments Booked",
          data: this.state.weekCount,
        },
      ],
      xaxis: {
        categories: this.state.formattedDates,
      },
      title: {
        text: "Scheduled Bookings Last 7 Days (Trend)",
        align: "left",
      },
    };

    var chartOne = new ApexCharts(
      document.querySelector("#chart"),
      lineOptions
    );

    chartOne.render();
  };

  render() {
    return (
      <div className="chartContainer">
        <div className="schedulerHeading">
          <h1 className="text-center py-4">Scheduler Analaytics</h1>
          <h3 className="text-center text-dark">(Total Booked)</h3>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-3 m-3 shadow-lg rounded">
            <h3 className="p-3 text-center text-dark font-weight-bold">
              Today
              <div
                className="result d-flex justify-content-center align-items-center"
                style={{ height: "250px" }}
              >
                <p className="text" style={{ fontSize: "4em" }}>
                  {this.state.todayCount}
                </p>
              </div>
            </h3>
          </div>
          <div className="col-lg-3 m-3 shadow-lg rounded">
            <h3 className="p-3 text-center text-dark font-weight-bold">
              Yesterday
              <div
                className="result d-flex justify-content-center align-items-center"
                style={{ height: "250px" }}
              >
                <p className="text" style={{ fontSize: "4em" }}>
                  {this.state.yesterdayCount}
                </p>
              </div>
            </h3>
          </div>
          <div className="col-lg-3 m-3 shadow-lg rounded">
            <h3 className="p-3 text-center text-dark font-weight-bold">
              Last 30 Days
              <div
                className="result d-flex justify-content-center align-items-center"
                style={{ height: "250px" }}
              >
                <p className="text" style={{ fontSize: "4em" }}>
                  {this.state.lastDayCount}
                </p>
              </div>
            </h3>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10 mb-4 shadow-lg rounded">
            <div id="chart"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Analytics;
