import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import AOS from "aos"; // Animate on scroll
import { Helmet } from "react-helmet";

import { VideoDataByYear } from "./VideoData";
import VideoComponent from "./VideoComponent";

const Videos = () => {
  const [show, setShow] = useState(false);
  const [template, setTemplate] = useState(1);
  const thisYear = new Date().getFullYear();
  AOS.refresh();

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    AOS.refresh();
  }, []);

  const openModal = () => setShow(true);
  const closeModal = () => setShow(false);

  const renderTemplate = () => {
    console.log("template is template", template);
    return (
      <VideoComponent
        currentYearData={template === 1 ? VideoDataByYear.currentYear : []}
        priorYearData={template === 2 ? VideoDataByYear.prior : []}
        template={template}
      />
    );
  };

  return (
    <div className="fluid-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mountain Manor Videos</title>
        <meta
          name="description"
          content="Mountain Manor videos showcase important information about the facility."
        />
        <link
          rel="canonical"
          href="https://mountainmanorskillednursing.com/videos"
        />
      </Helmet>
      <div className="videosContainer">
        <div className="videoGallery">
          <div className="jumbotron jumbotron-fluid">
            <div className="videoContainer">
              <video
                autoPlay={isMobile === false ? true : false}
                muted
                loop
                style={{ width: "100%" }}
              >
                <source
                  src="https://mountainmanorbucket.s3.us-west-1.amazonaws.com/mountainmanorassets/MMC-Web-Trailer.mp4"
                  type="video/mp4"
                />
              </video>
              <div className="container">
                <div className="videoOverlay">
                  <div data-aos="fade-down">
                    <h1>Experience Exceptional Healthcare</h1>
                    <p className="lead">
                      Our team is dedicated to paving the way for residents
                      seeking healthcare, delivering top-quality care that
                      exceeds expectations. Come see for yourself how we can
                      help you achieve your health goals and live your best
                      life.
                    </p>
                    <button className="btn btn-primary" onClick={openModal}>
                      WATCH THE VIDEO
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal size="lg" show={show} onHide={closeModal}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <video controls autoPlay style={{ width: "100%" }}>
                <source
                  src="https://mountainmanorbucket.s3.us-west-1.amazonaws.com/mountainmanorassets/MMC-Promo-V1-2023.mp4"
                  type="video/mp4"
                />
              </video>
            </Modal.Body>
          </Modal>

          <div className="container">
            {/* Videos */}
            <div className="subvideo-container mt-4">
              <div
                className="year-container py-3"
                style={{ backgroundColor: "#06A7D3", cursor: "pointer" }}
              >
                <span
                  className={`d-inline-block text-white px-2 ${
                    template === 1 ? "active" : ""
                  }`}
                  style={{ fontSize: "30px" }}
                  onClick={() => setTemplate(1)}
                >
                  {thisYear}
                </span>
                <span
                  className="d-inline-block text-white"
                  style={{ fontSize: "30px", cursor: "pointer" }}
                >
                  |
                </span>
                <span
                  className={`d-inline-block text-white px-2 ${
                    template === 2 ? "active" : ""
                  }`}
                  style={{ fontSize: "30px" }}
                  onClick={() => setTemplate(2)}
                >
                  Prior
                </span>
              </div>
              <h1 className="py-3 text-center">Mountain Manor Videos</h1>
              <div>{renderTemplate(template)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Videos;
