import React from "react";

import blogtopicsService from "../../services/Blogservice";

import CKEditor from "ckeditor4-react";

class AddBlogtopic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      blogTopic: "",
      blogPicture: "",
      fileObject: "",
      fileName: "",
      fileType: "",
      fileSize: "",
      filePayload: "",
      blogContent: "",
      blogAuthor: "",
      errorMsg: false,
      errorMsg2: false,
      errorMsg3: false,
      errorMsg4: false,
    };
  }

  userData = () => {
    // Get logged in user
    const userObject = JSON.parse(localStorage.getItem("userData"));

    let blogName = userObject.firstName + " " + userObject.lastName;

    this.setState({
      blogAuthor: blogName,
    });

    // console.log(userObject);
  };

  // test = () => {
  //   let testString = "test Beginning.jpg";

  //   let test = testString.replace(" ", "_");

  //   console.log(test);
  // };

  componentDidMount = () => {
    this.userData();

    // this.test();
  };

  fileUpload = (e) => {
    let file = e.target.files[0];
    var dataTypeURL = new FileReader();
    var arrayBuffer = new FileReader();

    let fileVal = file.name;

    this.setState({
      fileObject: file,
      fileName: fileVal.replace(/\s/g, ""),
      fileSize: file.size,
      fileType: file.type,
    });

    dataTypeURL.onload = (e) => {
      this.setState({
        filePayload: e.target.result,
        blogPicture: e.target.result,
      });
    };
    dataTypeURL.readAsDataURL(file);
    arrayBuffer.readAsArrayBuffer(file);
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    let fileCheck = Math.floor(Math.log(this.state.fileSize) / Math.log(1024));
    let allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];

    // Form Validation
    if (this.state.blogTopic === "" || this.state.blogContent === "") {
      this.setState({
        errorMsg4: true,
      });
      //Check if a uploaded photo was taken.
    } else if (this.state.fileObject === "") {
      this.setState({
        errorMsg: true,
        errorMsg2: false,
        errorMsg3: false,
      });
    } else if (!allowedFileTypes.includes(this.state.fileType)) {
      // check file type
      this.setState({
        errorMsg: false,
        errorMsg2: true,
        errorMsg3: false,
      });
      //console.log('wrong file type');
    } else if (fileCheck >= 2) {
      // check file size
      this.setState({
        errorMsg: false,
        errorMsg2: false,
        errorMsg3: true,
      });
      //console.log('file is too big');
    } else {
      this.setState({
        isLoading: true,
        errorMsg: false,
        errorMsg2: false,
        errorMsg3: false,
        errorMsg4: false,
      });
      // Passed all file checks, upload the photo with data.
      const uploadData = {
        blogTopic: this.state.blogTopic,
        blogContent: this.state.blogContent,
        fileType: this.state.fileType,
        fileName: this.state.fileName,
        fileSize: this.state.fileSize,
        blogAuthor: this.state.blogAuthor,
        filePayload: this.state.filePayload,
      };
      const insertData = await blogtopicsService.insertblogPost(uploadData);

      // Wait a couple of seconds to allow the picture to upload to s3. Once in s3, reference it in the db and load the posts again in real time after submitting.
      setTimeout(() => {
        this.setState({
          isLoading: false,
        });
        // Close Modal after submission
        this.props.handleClose();

        // Re-render component real time
        this.props.selectblogTopics();
      }, 3000);
    }
  };

  render() {
    return (
      <div className="formContainer">
        <div className="row">
          <div className="col-md-12">
            {/* Please upload a photo. */}
            {this.state.errorMsg === true ? (
              <p style={{ color: "red" }}>Please upload a photo</p>
            ) : null}
            {/* Wrong file type. */}
            {this.state.errorMsg2 === true ? (
              <p style={{ color: "red" }}>Please upload only JPG or PNG</p>
            ) : null}
            {/* File is too big. */}
            {this.state.errorMsg3 === true ? (
              <p style={{ color: "red" }}>Please upload a file less than 3MB</p>
            ) : null}
            {this.state.errorMsg4 === true ? (
              <p style={{ color: "red" }}>Please fill out all fields</p>
            ) : null}
            <form
              method="POST"
              autoComplete="off"
              onSubmit={(e) => this.onSubmit(e)}
            >
              <input
                type="text"
                className="form-control mb-4"
                name="blogTopic"
                placeholder="Blog Topic"
                onChange={(e) => this.onChange(e)}
                value={this.state.blogTopic}
              />
              <CKEditor
                className="form-control mb-4"
                data={this.state.blogContent}
                onChange={(event, editor) => {
                  const data = event.editor.getData();
                  this.setState({
                    blogContent: data,
                  });
                  //console.log({ event, editor, data });
                }}
              />
              <input
                type="file"
                className="d-block mt-4"
                name="blogPicture"
                onChange={(e) => this.fileUpload(e)}
              />
              <button type="submit" className="btn btn-default mt-4">
                {this.state.isLoading ? (
                  <div className="loaderContainer">
                    <i className="fas fa-spinner" id="smloader" /> Please wait
                    ...!
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default AddBlogtopic;
