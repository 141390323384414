import React from 'react';

import { Link } from 'react-router-dom';

import Button from "@material-ui/core/Button";

import AOS from "aos";

const FeedbackSection = () => {
    AOS.init();
    return (
        <div className="section-container mt-3 text-center" style={{ backgroundColor: '#35A768' }} data-aos="fade-down">
            <div className="d-flex flex-column align-items-center">
                <h2 className='text-white p-3'><strong>Tell us about your experience at our facility</strong></h2>
                <p className='text-white p-3'>How was your experience at the nursing facility? We would love to hear your feedback!</p>
                <Link to="/feedback" className='feedback-button'>LEAVE FEEDBACK</Link>
            </div>
        </div>
    )
}

export default FeedbackSection;