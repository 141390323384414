import serviceBase from "./serviceBase";

const scheduleService = {
  selectSchedule: (data) => serviceBase.post("/api/selectSchedule", data),
  selectadminSchedule: (data) =>
    serviceBase.post("/api/selectadminSchedule", data),
  deleteadminSchedule: (data) =>
    serviceBase.post("/api/deleteadminSchedule", data),
  editadminSchedule: (data) => serviceBase.post("/api/editadminSchedule", data),
  insertSchedule: (data) => serviceBase.post("/api/insertSchedule", data),
};

export default scheduleService;
