import React from "react";

import Invoices from "./Invoices";
import Modifyusers from "./Modifyusers";
import Modifyschedule from "./ModifySchedule";
import DetailedReport from "./DetailedReport";
import Analytics from "./Analytics";
import Moment from "moment";

import dataTip from "data-tip";

import { Link } from "react-router-dom";

import Detailedservice from "../../services/Detailedservice";

class Admindashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      roleId: "",
      userId: "",
      renderTemplate: 0,
      todayCount: "",
      yesterdayCount: "",
      lastDayCount: "",
      weekCount: [],
      formattedDates: [],
    };
  }

  logOut = () => {
    // Kill the session
    localStorage.clear();
    // Go back to home page
    this.props.history.push("/");
  };

  userData = async () => {
    // Check if user is logged in
    if (localStorage.getItem("userData") === null) {
      this.props.history.push("/");
    } else {
      const userObject = JSON.parse(localStorage.getItem("userData"));

      await this.setState({
        email: userObject.email,
        firstName: userObject.firstName,
        lastName: userObject.lastName,
        roleId: userObject.role_id,
        userId: userObject.user_id,
      });
    }
  };

  renderAction = async (e, templateId) => {
    if (templateId === 5) {
      await this.setState({ renderTemplate: 5 });
    } else if (templateId === 4) {
      await this.setState({ renderTemplate: 4 });
    } else if (templateId === 3) {
      await this.setState({ renderTemplate: 3 });
    } else if (templateId === 2) {
      await this.setState({ renderTemplate: 2 });
    } else if (templateId === 1) {
      await this.setState({ renderTemplate: 1 });
    }
    // console.log(this.state.renderTemplate);
  };

  renderTemplate = () => {
    switch (this.state.renderTemplate) {
      case 1:
        return <Invoices />;
        break;
      case 2:
        return <Analytics />;
        break;
      case 3:
        return <Modifyusers />;
        break;
      case 4:
        return <Modifyschedule />;
        break;
      case 5:
        return <DetailedReport />;
        break;
      default:
        return null;
    }
  };

  componentDidMount = async () => {
    // Check if admin is logged in

    await this.userData();
  };

  render() {
    return (
      <div className="admindashboard">
        <div className="fluid-container">
          {/* <div className="adminfullHeader text-right">
            <div
              className="logOut data-tip-bottom"
              data-tip="Log Out"
              onClick={this.logOut}
            >
              <i className="fas fa-sign-out-alt fa-2x" />
            </div>
            <div
              className="profile data-tip-bottom"
              data-tip={this.state.firstName + " " + this.state.lastName}
            >
              <i className="fas fa-user-circle fa-2x" />
            </div>
          </div> */}
          <div className="sideNav">
            <ul className="nav flex-column">
              <li className="nav-item data-tip-bottom" data-tip="Home">
                <Link to="/" className="nav-link active">
                  <i className="fas fa-home  fa-2x" />
                </Link>
              </li>
              <li
                className="nav-item data-tip-bottom"
                data-tip="Invoices"
                onClick={(e) => this.renderAction(e, 1)}
              >
                <Link className="nav-link" to="#">
                  <i className="fas fa-file-invoice fa-2x" />
                </Link>
              </li>
              <li
                className="nav-item data-tip-bottom"
                data-tip="Modify Scheduler"
                onClick={(e) => this.renderAction(e, 4)}
              >
                <Link className="nav-link" to="#">
                  <i className="fas fa-calendar-alt fa-2x" />
                </Link>
              </li>
              <li
                className="nav-item data-tip-bottom"
                data-tip="Detailed Report"
                onClick={(e) => this.renderAction(e, 5)}
              >
                <Link className="nav-link" to="#">
                  <i className="fas fa-table fa-2x" />
                </Link>
              </li>
              <li
                className="nav-item data-tip-bottom"
                data-tip="Analytics"
                onClick={(e) => this.renderAction(e, 2)}
              >
                <Link className="nav-link" to="#">
                  <i className="fas fa-chart-line fa-2x" />
                </Link>
              </li>
              <li
                className="nav-item data-tip-bottom"
                data-tip="Modify User Access"
                onClick={(e) => this.renderAction(e, 3)}
              >
                <Link className="nav-link" to="#">
                  <i className="fas fa-users-cog fa-2x" />
                </Link>
              </li>
              <li className="nav-item data-tip-bottom" data-tip="Blog">
                <Link className="nav-link" to="/blog">
                  <i className="fas fa-blog fa-2x" />
                </Link>
              </li>
            </ul>
          </div>
          <div className="adminDashboard">
            <div className="row">
              <div className="dashboardHeader col-md-12">
                <h4>Admin Dashboard</h4>
                <h2>
                  Hello, {this.state.firstName + " " + this.state.lastName}
                </h2>
              </div>
              <Link to="/blog">
                <button
                  className="btn btn-primary"
                  style={{ color: "black", marginTop: "20px" }}
                >
                  Go to Blog
                </button>
              </Link>

              <button
                className="btn btn-primary"
                style={{
                  color: "black",
                  marginTop: "20px",
                  marginLeft: "20px",
                }}
                onClick={(e) => this.renderAction(e, 1)}
              >
                Go to Invoices
              </button>

              <div className="dashboardTemplate col-md-12 mt-4">
                {/* Render Template based on templateId clicked on */}
                {this.renderTemplate()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Admindashboard;
