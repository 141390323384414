import React, { useEffect, useState } from "react";

import yelpService from "../../services/Yelpservice";
import yelpHardCodedReviews from "./YelpData";

const UserReviews = () => {
  const [users, setUsers] = useState({ reviews: [] });
  const getReviews = async () => {
    try {
      const getUsers = await yelpService.getUsers(); // Assume this returns an object with a reviews array
      // Combine fetched reviews with hardcoded reviews and filter for 5-star ratings
      const combinedReviews = [
        ...yelpHardCodedReviews,
        ...getUsers.reviews,
      ].filter((review) => review.rating === 5);
      setUsers({ ...getUsers, reviews: combinedReviews }); // Merge and update state
    } catch (error) {
      console.error("Error fetching reviews:", error);
      // Fallback to hardcoded reviews if fetch fails
      setUsers({
        reviews: yelpHardCodedReviews.filter((review) => review.rating === 5),
      });
    }
  };

  useEffect(() => {
    // Get the reviews
    getReviews();
  }, []);
  return (
    <div
      className="reviewsContainer"
      data-aos="fade-down"
      data-aos-offset="300"
    >
      <div className="reviewsHeader pt-3">
        <h1 className="h2Header">Reviews</h1>
        <p className="text-center pb-4">
          Take a look at what some of our customers had to say about there great
          experience with our facility.
        </p>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-4 shadow-lg text-center p-3 rounded">
          <div className="rounded">
            <i className="fas fa-star fa-2x" />
            <i className="fas fa-star fa-2x" />
            <i className="fas fa-star fa-2x" />
            <i className="fas fa-star fa-2x" />
            <i className="fas fa-star fa-2x" />
            &nbsp;
            <span className="lead">5.0</span>
          </div>
        </div>
      </div>
      <div className="review-category mt-2 d-flex justify-content-center">
        <div className="review-header">
          <img
            src="/images/yelp-reviews.jpg"
            height={"250px"}
            width="250px"
            alt="Mountain Manor Yelp Reviews"
          />
        </div>
      </div>
      <div className="reviewContent my-5">
        <div className="row justify-content-center text-center">
          {users.reviews.length > 0 ? (
            users.reviews.map((review, index) => (
              <div className="col-sm-12 my-3 col-lg-4 rounded" key={index}>
                <div className="reviewsubContent shadow-lg">
                  <div className="personInfo">
                    <div className="rounded rounded-circle">
                      <i className="far fa-user fa-4x"></i>
                    </div>
                    <p className="py-3">
                      <strong>{review.userName}</strong>
                    </p>
                    {Array.from({ length: review.rating }, () => (
                      <i className="fas fa-star fa-2x"></i>
                    ))}
                  </div>
                  <div className="personContent">
                    <p className="p-3 text-left">{review.text}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="col-lg-4 shadow-lg text-center p-3 rounded">
              <p>Loading reviews, please wait...</p>
            </div>
          )}
        </div>
      </div>
      {/* Google Reviews Hardcoded for now */}
      <div className="review-category mt-2 d-flex justify-content-center">
        <img
          src="/images/google-reviews.jpg"
          height={"250px"}
          width="250px"
          alt="Mountain Manor Google Reviews"
        />
      </div>
      <div className="reviewContent my-5">
        <div className="row justify-content-center text-center">
          <div className="col-sm-12 my-3 col-lg-4 rounded">
            <div className="reviewsubContent shadow-lg">
              <div className="personInfo">
                <div className="rounded rounded-circle">
                  <i className="far fa-user fa-4x"></i>
                </div>
                <p className="py-3">
                  <strong>{"Emma E."}</strong>
                </p>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
              </div>
              <div className="personContent">
                <p className="p-3 text-left">
                  This is honestly one of the greatest skilled nursing facility!
                  My mother was admitted here for skilled nursing care &amp;
                  rehabilitation. I was...{" "}
                  <a
                    href={
                      "https://www.google.com/maps/place/Mountain+Manor/@38.6119554,-121.3312113,17z/data=!4m7!3m6!1s0x809adc41b065bccd:0xab8a86e9cc70c2d!8m2!3d38.6119512!4d-121.3290173!9m1!1b1"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    User Reviews
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 my-3 col-lg-4 rounded">
            <div className="reviewsubContent shadow-lg">
              <div className="personInfo">
                <div className="rounded rounded-circle">
                  <i className="far fa-user fa-4x"></i>
                </div>
                <p className="py-3">
                  <strong>{"Len B."}</strong>
                </p>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
              </div>
              <div className="personContent">
                <p className="p-3 text-left">
                  I was recently a resident for two weeks at Mountain Manor for
                  physical therapy. I had been at another facilities in the past
                  and really liked that facility. But, I found Mountain Manor to
                  be a much nicer and a more enjoyable facility. It is...{" "}
                  <a
                    href={
                      "https://www.google.com/maps/place/Mountain+Manor/@38.6119554,-121.3312113,17z/data=!4m7!3m6!1s0x809adc41b065bccd:0xab8a86e9cc70c2d!8m2!3d38.6119512!4d-121.3290173!9m1!1b1"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    User Reviews
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 my-3 col-lg-4 rounded">
            <div className="reviewsubContent shadow-lg">
              <div className="personInfo">
                <div className="rounded rounded-circle">
                  <i className="far fa-user fa-4x"></i>
                </div>
                <p className="py-3">
                  <strong>{"Sara O."}</strong>
                </p>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
              </div>
              <div className="personContent">
                <p className="p-3 text-left">
                  My brother was here after his hiking injury. The physical
                  therapy staff exceeded our expectations I highly recommend
                  this facility.{" "}
                  <a
                    href={
                      "https://www.google.com/maps/place/Mountain+Manor/@38.6119554,-121.3312113,17z/data=!4m7!3m6!1s0x809adc41b065bccd:0xab8a86e9cc70c2d!8m2!3d38.6119512!4d-121.3290173!9m1!1b1"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    User Reviews
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 my-3 col-lg-4 rounded">
            <div className="reviewsubContent shadow-lg">
              <div className="personInfo">
                <div className="rounded rounded-circle">
                  <i className="far fa-user fa-4x"></i>
                </div>
                <p className="py-3">
                  <strong>{"Valerie G."}</strong>
                </p>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
              </div>
              <div className="personContent">
                <p className="p-3 text-left">
                  If you want good care, love, and kindness choose Mountain
                  Manor! Everyone should know this is the best facility!{" "}
                  <a
                    href={
                      "https://www.google.com/maps/place/Mountain+Manor/@38.6119554,-121.3312113,17z/data=!4m7!3m6!1s0x809adc41b065bccd:0xab8a86e9cc70c2d!8m2!3d38.6119512!4d-121.3290173!9m1!1b1"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    User Reviews
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 my-3 col-lg-4 rounded">
            <div className="reviewsubContent shadow-lg">
              <div className="personInfo">
                <div className="rounded rounded-circle">
                  <i className="far fa-user fa-4x"></i>
                </div>
                <p className="py-3">
                  <strong>{"Dh O."}</strong>
                </p>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
              </div>
              <div className="personContent">
                <p className="p-3 text-left">
                  What can I say? mountain manor is a great building to start
                  with ! once every 3 months the activity director Carissa would
                  hold a candle light dinner for the patients which I think is a
                  wonderful idea.This activity makes the patients feel...{" "}
                  <a
                    href={
                      "https://www.google.com/maps/place/Mountain+Manor/@38.6119554,-121.3312113,17z/data=!4m7!3m6!1s0x809adc41b065bccd:0xab8a86e9cc70c2d!8m2!3d38.6119512!4d-121.3290173!9m1!1b1"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    User Reviews
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 my-3 col-lg-4 rounded">
            <div className="reviewsubContent shadow-lg">
              <div className="personInfo">
                <div className="rounded rounded-circle">
                  <i className="far fa-user fa-4x"></i>
                </div>
                <p className="py-3">
                  <strong>{"Grace L."}</strong>
                </p>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
                <i className="fas fa-star fa-2x"></i>
              </div>
              <div className="personContent">
                <p className="p-3 text-left">
                  i highly recommend mountain manor to anyone looking for
                  quality care and therapeutic treatment. the care here is
                  excellent!{" "}
                  <a
                    href={
                      "https://www.google.com/maps/place/Mountain+Manor/@38.6119554,-121.3312113,17z/data=!4m7!3m6!1s0x809adc41b065bccd:0xab8a86e9cc70c2d!8m2!3d38.6119512!4d-121.3290173!9m1!1b1"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    User Reviews
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserReviews;
