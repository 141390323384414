import React from "react";

import { Link } from "react-router-dom";

import MyTheme from "../common/Theme";

import subscriberService from "../../services/Formservice";

import OwlCarousel from "react-owl-carousel";
import { Helmet } from "react-helmet";
import "../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";
import { MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { isMobile } from "react-device-detect";
import AOS from "aos"; // Animate on scroll
import Moment from "moment";
import $ from "jquery";

import FeedbackSection from "../components/FeedbackSection";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailError: "",
      emailExists: false,
      scrollDown: false,
      showVideo: false,
      removeVideo: false,
    };
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  formValidate = () => {
    let isError = false;
    const errors = {
      emailError: "",
    };

    // Check if a valid email address
    if (this.state.email.indexOf("@") === -1) {
      isError = true;
      errors.emailError = "* Please enter a valid email address";
    }

    // make a copy of this into state
    this.setState({
      ...this.state,
      ...errors,
      isError,
    });

    return isError;
  };

  openModal = () => {
    this.setState({
      show: true,
    });
  };

  closeModal = () => {
    this.setState({
      show: false,
    });
  };

  showVideo = () => {
    $(".clickedVideo").slideDown(1000);

    setTimeout(() => {
      this.setState({
        showVideo: true,
      });
    }, 1500);
  };

  removeVideo = () => {
    this.setState({
      showVideo: false,
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const err = this.formValidate();

    if (!err) {
      // clear the form
      this.setState({
        email: "",
        emailError: "",
      });
      // Send form details to back end

      const subscriberData = {
        email: this.state.email,
        createdDate: Moment().format("YYYY-MM-DD"),
      };

      // Check to see if user exists or not
      const sendSubscriber = await subscriberService.emailsubscribeAuth(
        subscriberData
      );

      //console.log(sendSubscriber.userExists);

      if (sendSubscriber.userExists === true) {
        alert("Sorry. You have already been added to the subscriber list");
      } else {
        alert(
          "Thank you. You have successfully been signed up for Mountain Manors email listings."
        );
      }
    }
  };

  scrollDown = (e) => {
    e.preventDefault();
    this.setState({
      scrollDown: true,
    });
    $("div.personContent").hide().css("height", "inherit").slideDown(500);
  };

  scrollUp = (e) => {
    e.preventDefault();
    this.setState({
      scrollDown: false,
    });
    $("div.personContent").show().css("height", "250px").slideUp(500);
  };

  componentDidMount() {
    AOS.init();
  }

  render() {
    return (
      <div className="homePage">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Mountain Manor Home Page</title>
          <meta
            name="description"
            content="Mountain Manor Skilled Nursing &amp; Assisted Living provides top quality nursing care for patients around Sacramento."
          />
          <link
            rel="canonical"
            href="https://mountainmanorskillednursing.com"
          />
        </Helmet>

        {/* Sliders */}
        <div className="fluid-container">
          <div className="mainSliders">
            <div className="fluid-container">
              <OwlCarousel
                className="owl-theme"
                items={1}
                loop={true}
                autoplay={true}
                dots={false}
                nav
                navText={[
                  "<i class='fas fa-angle-left fa-3x'></i>",
                  "<i class='fas fa-angle-right fa-3x'></i>",
                ]}
                autoplayTimeout={10000}
              >
                <div className="item">
                  <img
                    src="/images/5-Star-CMS-logov2.webp"
                    alt="CMS Five Star"
                  />
                  <div className="overlay">
                    <div data-aos="fade-down">
                      <h1>Preferred Choice for Quality Care!</h1>
                      <p>
                        Mountain Manor Skilled Nursing &amp; Assisted Living is
                        the top choice for doctors, medical staff, patients, and
                        their families in the Carmichael, Citrus Heights,
                        Arden-Arcade, Roseville, and Sacramento Areas. Our
                        comprehensive services include Physical, Occupational,
                        and Speech Therapies, provided with exceptional care by
                        Licensed RN's, LVN's, and dedicated staff. We take pride
                        in being the preferred post-acute destination for
                        quality care in the region.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <img
                    src="/images/mmcustomersatisfaction.webp"
                    alt="Mountain Manor Customer Satisfaction Message"
                    style={{ backgroundPositionY: "top" }}
                  />
                  <div className="overlay">
                    <div data-aos="fade-down">
                      <h1>
                        Mountain Manor is Committed to Customer Satisfaction!
                      </h1>
                      <p>
                        Come and see why our Google review rating is 20% higher
                        than the Sacramento Region average with double the
                        average reviews for Skilled Nursing Facilities.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <img
                    src="/images/mmshortpostcare.webp"
                    alt="Welcome to Mountain Manor Greeting"
                  />
                  <div className="overlay">
                    <div data-aos="fade-down">
                      <h1>
                        Welcome to Mountain Manor Skilled Nursing &amp; Assisted
                        Living
                      </h1>
                      <p>
                        We are a locally owned and operated Senior Living Center
                        in Carmichael, CA offering two different levels of care
                        under the same roof. Please select the level of care
                        below to learn more!
                      </p>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>

        <div className="fluid-container two-column mb-3">
          <div className="h2Header">
            <h2 className="p-3">Please Select Your Level of Care</h2>
          </div>
          <div className="row no-gutters">
            <div className="col-md-6">
              <div className="overlay img-left">
                <div className="overlay-content">
                  <div data-aos="fade-down" data-aos-once="true">
                    <h3>Skilled Nursing &amp; Post-Acute Care</h3>
                  </div>
                  <Link to="/services">
                    <button className="btn btn-primary">
                      VIEW INFORMATION
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="overlay img-right">
                <div className="overlay-content">
                  <div
                    data-aos="fade-down"
                    data-aos-offset="300"
                    data-aos-once="true"
                  >
                    <h3>Assisted Living</h3>
                  </div>
                  <Link to="/services">
                    <button className="btn btn-primary">
                      VIEW INFORMATION
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mainVideo">
          <div className="container-fluid">
            <div
              className="clickedVideo"
              style={{
                display: this.state.showVideo === false ? "none" : "block",
              }}
            >
              <div className="removeVod p-3 text-right">
                <i
                  className="fa fa-times fa-2x"
                  aria-hidden="true"
                  style={{ color: "#fff", cursor: "pointer" }}
                  onClick={this.removeVideo}
                  title="Hide Video"
                />
              </div>
              <div
                className="vodContainer d-flex justify-content-center align-items-center"
                style={{ height: "85%" }}
              >
                <div className="vodContent">
                  {!this.state.showVideo ? (
                    <i
                      className="fa fa-spinner fa-4x"
                      id="vodLoader"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <video controls autoPlay>
                      <source
                        src="https://mountainmanorbucket.s3.us-west-1.amazonaws.com/mountainmanorassets/MM-Meet-Jim-Jordan.mp4"
                        type="video/mp4"
                      />
                    </video>
                  )}
                </div>
              </div>
            </div>

            <div className="videoContainer">
              <video autoPlay={isMobile === false ? true : false} muted loop>
                <source
                  src="https://mountainmanorbucket.s3.us-west-1.amazonaws.com/mountainmanorassets/MM-Meet-Jim-Jordan.mp4"
                  type="video/mp4"
                />
              </video>
              <div className="container">
                <div className="videoOverlay">
                  <div data-aos="fade-down">
                    <h1>Meet Jim Jordan</h1>
                    <p>
                      Our skilled nursing facility provides top-notch short term
                      living with a dedicated team of healthcare professionals
                      who are committed to helping you get healthy and return
                      home.
                    </p>
                    <button
                      className="btn btn-primary"
                      onClick={this.showVideo}
                    >
                      WATCH THE VIDEO
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Feedback Section */}
        <FeedbackSection />

        <div className="fluid-container two-column pt-3">
          <div className="twoColumn email">
            <div className="container">
              <div className="row">
                <div className="col-md-6 left-column">
                  <div data-aos="fade-down" data-aos-once="true">
                    <h2 className="pt-4">Join Our Community</h2>
                    <p className="py-4">
                      Want to know what is happening at Mountain Manor? Join our
                      mailing list.
                    </p>
                    <div className="form-group">
                      <MuiThemeProvider theme={MyTheme}>
                        <form
                          method="POST"
                          autoComplete="off"
                          onSubmit={(e) => this.onSubmit(e)}
                        >
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            name="email"
                            type="email"
                            value={this.state.email}
                            label="Email Address"
                            error={this.state.emailError ? true : false}
                            helperText={this.state.emailError}
                            variant="outlined"
                            onChange={(e) => this.onChange(e)}
                          />
                          <Button variant="outlined" type="submit">
                            JOIN
                          </Button>
                        </form>
                      </MuiThemeProvider>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 right-column">
                  <div className="brochureContainer">
                    <h2 className="pt-4">View Our Brochure</h2>
                    <p className="py-4">
                      View Mountain Manors exclusive brochure of the latest and
                      best services we have to offer.
                    </p>
                    <div className="brochureButton mb-5">
                      <a
                        className="btn btn-primary"
                        id="uniqueButton"
                        // href="https://online.fliphtml5.com/vmbic/fymu/"
                        href="https://online.fliphtml5.com/vmbic/hwwk/"
                        target="_blank"
                        title="View Mountain Manor Brochure"
                      >
                        <i className="fas fa-book"></i> VIEW BROCHURE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
