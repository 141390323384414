import React from "react";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";

const Faq = () => {
  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mountain Manor Frequently Asked Questions | FAQ</title>
        <meta
          name="description"
          content="Mountain Manor most popular questions about our facility for Frequently Asked Questions"
        />
        <link
          rel="canonical"
          href="https://mountainmanorskillednursing.com/faq"
        />
      </Helmet>
      <div className="tutorial">
        <h2 className="py-4 text-center">
          Scheduler FAQ (Frequently Asked Questions)
        </h2>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">
              What are the visitor guidelines? (Updated 10/14/2022)
            </Typography>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails>
            <div>
              <ul style={{ paddingLeft: "20px" }}>
                <li style={{ listStyle: "disc", lineHeight: "1.4em" }}>
                  All Visitors must be screened in the lobby for temperature and
                  COVID symptoms (via a kiosk).
                </li>
                <li style={{ listStyle: "disc", lineHeight: "1.4em" }}>
                  If you are feeling sick in any way, please delay your visit
                  until you are better.
                </li>
                <li style={{ listStyle: "disc", lineHeight: "1.4em" }}>
                  All Visitors must wear a mask at all times while in the
                  building, including while visiting in a resident's room.
                </li>
                <li style={{ listStyle: "disc", lineHeight: "1.4em" }}>
                  Visitors are asked to visit in the resident's room or at a
                  designated location, but to not roam elsewhere in the
                  building.
                </li>
                <li style={{ listStyle: "disc", lineHeight: "1.4em" }}>
                  During times of increased community transmission of COVID in
                  Sacramento County, these rules are subject to change.
                </li>
                <li style={{ listStyle: "disc", lineHeight: "1.4em" }}>
                  Recommended visiting hours are from 8:30 AM to 5 PM, outside
                  of these times please be respectful of other residents
                  resting.
                </li>
              </ul>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">
              How do I schedule an appointment?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              To schedule an appointment, select the appropriate day and
              timeframe for that specific day on the scheduler. Once all the
              information is filled out, simply hit the "Add Appointment" button
              at the bottom of the popup window and the appointment will be set
              in the scheduler for that timeslot.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">
              How do I enter in my details into the appointment I selected?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              Once you select a day and timeframe for the appointment, you'll
              see a screen where you can enter in some details for the
              appointment.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">How long are the appointments?</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>Each appointment is only an hour long.</Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">
              How come certain times are unavailable?
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              The scheduler's availability changes constantly. On certain days
              times will reflect differently and matches our current schedule
              availability times to hold appointments.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h5">
              I need help using the scheduler
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              If you're having trouble using the scheduler or don't know how to
              book an appointment, feel free to contact us. We'll be happy to
              assist you!
            </Typography>
          </ExpansionPanelDetails>
          <ExpansionPanelDetails>
            <Link className="btn btn-primary" to="/contactUs">
              Get in Contact
            </Link>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </div>
  );
};

export default Faq;
