import React, { useState, useEffect } from "react";

import MyTheme from "../common/Theme";

import { Modal, Button } from "react-bootstrap";
import { withStyles } from "@material-ui/core/styles";

import MaskedInput from "react-text-mask";

import Scheduleservice from "../../services/Scheduleservice";
import Moment from "moment";

import { MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Grid from "@material-ui/core/Grid";

import scheduleService from "../../services/Scheduleservice";

// full calendar api dependencies
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

const styles = (theme) => ({
  root: {
    width: "90%",
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

const ModifySchedule = () => {
  const [scheduleData, setScheduleData] = useState([]);
  const [modalState, setModal] = useState(false);
  const [appointmentData, setAppointmentData] = useState([
    {
      id: "",
      info: [],
      title: "",
    },
  ]);

  const closeModal = () => {
    setModal(false);
  };

  const selectAdminData = async () => {
    const selectAppointments = await scheduleService.selectadminSchedule();

    setScheduleData(selectAppointments);

    // console.log("selected..", selectAppointments);
  };

  useEffect(() => {
    // console.log("data is...", appointmentData.info);
    selectAdminData();
  }, [appointmentData]);

  const handleEventClick = ({ event }) => {
    const eventId = event._def.publicId;
    const appointmentInfo = event._def.extendedProps;
    const appointmentTitle = event._def.title;

    setAppointmentData({
      id: eventId,
      info: appointmentInfo,
      title: appointmentTitle,
    });

    setModal(true);
  };
  //console.log("event data...", appointmentData);

  const deleteAppointment = async (appointmentId) => {
    // Delete the schedule
    const deleteSchedule = await scheduleService.deleteadminSchedule({
      Id: appointmentId,
    });

    // Refresh Calendar
    selectAdminData();
    setModal(false);
  };

  const onChange = (e) => {
    let prevState = Object.assign({}, appointmentData.info);
    let targetName = e.target.name;
    prevState[targetName] = e.target.value;
    appointmentData.info = prevState;
    //console.log(appointmentData);

    setAppointmentData({
      ...appointmentData,
      title: e.target.value,
      appointmentData,
    });

    //console.log("test222", appointmentData);
  };

  const editAppointment = async (appointmentId) => {
    let editData = {
      Id: appointmentId,
      RoleId: undefined ? "3" : "3",
      Subject: appointmentData.info.title,
      FullName: appointmentData.info.FullName,
      Resident: appointmentData.info.Resident,
      CommunicationMethod: appointmentData.info.CommunicationMethod,
      Email: appointmentData.info.Email,
      Phone: appointmentData.info.Phone,
      Description:
        appointmentData.info.Reason === undefined
          ? null
          : appointmentData.info.Reason,
      DateModifed: Moment().format("YYYY-MM-DD hh:mm:ss"),
    };

    const updateSchedule = Scheduleservice.editadminSchedule(editData);

    // Clear all data and fields
    setScheduleData({
      scheduleData: [],
    });
    setAppointmentData({
      appointmentData: [
        {
          id: "",
          info: [],
          title: "",
        },
      ],
    });
    selectAdminData();
    setModal(false);
  };

  // update Schedule Data into DB
  // const editSchedule = Scheduleservice.editadminSchedule(editData);
  // Refresh Calendar
  //selectAdminData();
  //setModal(false);

  // console.log("selected222..", scheduleData);

  return (
    <div className="fluid-container">
      <div className="modify-schedule-container">
        <div className="container">
          <FullCalendar
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            dayMaxEvents={true}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            selectable={true}
            events={scheduleData}
            eventClick={handleEventClick}
          />

          {/* Modal */}
          <Modal
            show={modalState}
            onHide={closeModal}
            keyboard={false}
            size="lg"
          >
            <Modal.Header>
              <Modal.Title>
                <div className="title-header text-center">
                  <h2>
                    <strong>Editing... "{appointmentData.title}"</strong>
                  </h2>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: "center" }}>
              <div className="form-container">
                <MuiThemeProvider theme={MyTheme}>
                  <p className="pt-3">
                    <strong>
                      {Moment(
                        appointmentData.info && appointmentData.info.StartTime
                      ).format("MM/DD/YYYY")}
                    </strong>
                  </p>
                  <p className="pb-3">
                    <strong>
                      {Moment(
                        appointmentData.info && appointmentData.info.StartTime
                      ).format("h:mm A") +
                        " - " +
                        Moment(
                          appointmentData.info && appointmentData.info.EndTime
                        ).format("h:mm A")}
                    </strong>
                  </p>

                  <div className="formContainer">
                    {scheduleData.title === "" ||
                    scheduleData.fullName === "" ||
                    scheduleData.resident === "" ||
                    scheduleData.email === "" ||
                    scheduleData.phone === "" ? (
                      <div
                        className="errorDiv text-center"
                        style={{
                          backgroundColor: "#180605",
                          color: "rgb(250, 179, 174)",
                          padding: "0 20px",
                        }}
                      >
                        <i className="fas fa-exclamation-circle fa-2x p-3"></i>
                        <span style={{ verticalAlign: "text-top" }}>
                          You are missing required fields. please go back and
                          correct them.
                        </span>
                      </div>
                    ) : null}
                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          name="title"
                          defaultValue={appointmentData.title}
                          type="text"
                          id="standard-required"
                          label="Appointment Subject"
                          variant="outlined"
                          onChange={(e) => onChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          name="FullName"
                          defaultValue={
                            appointmentData.info &&
                            appointmentData.info.FullName
                          }
                          type="text"
                          id="standard-required"
                          label="Full Name"
                          variant="outlined"
                          onChange={(e) => onChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          name="Resident"
                          defaultValue={
                            appointmentData.info &&
                            appointmentData.info.Resident
                          }
                          type="text"
                          id="standard-required"
                          label="Resident Requesting to
                        Speak to"
                          variant="outlined"
                          onChange={(e) => onChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="filled-select-currency-native"
                          select
                          label="Preferred Method of
          Communication"
                          name="CommunicationMethod"
                          onChange={(e) => onChange(e)}
                          SelectProps={{
                            native: true,
                          }}
                          defaultValue={
                            appointmentData.info &&
                            appointmentData.info.CommunicationMethod
                          }
                        >
                          <option value="In Person">In Person</option>
                          <option value="Zoom">Zoom</option>
                          <option value="Skype">Skype</option>
                          <option value="Facetime">Facetime</option>
                          <option value="Viber">Viber</option>
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          name="Email"
                          defaultValue={
                            appointmentData.info && appointmentData.info.Email
                          }
                          type="email"
                          id="standard-required"
                          label="Email Address"
                          variant="outlined"
                          onChange={(e) => onChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl>
                          <InputLabel htmlFor="formatted-text-mask-input">
                            Phone Number
                          </InputLabel>
                          <Input
                            type="tel"
                            defaultValue={
                              appointmentData.info && appointmentData.info.Phone
                            }
                            id="standard-required"
                            name="Phone"
                            onChange={(e) => onChange(e, "textmask")}
                            inputComponent={TextMaskCustom}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          InputLabelProps={{ shrink: true }}
                          multiline
                          rows="5"
                          name="Reason"
                          id="standard-required"
                          defaultValue={
                            appointmentData.info && appointmentData.info.Reason
                          }
                          label="Reason for Visit"
                          variant="outlined"
                          onChange={(e) => onChange(e)}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </MuiThemeProvider>
              </div>
            </Modal.Body>
            <Modal.Footer style={{ display: "block", textAlign: "center" }}>
              <Button
                variant="danger"
                className="mx-2"
                onClick={() => deleteAppointment(appointmentData.id)}
                size="lg"
                style={{
                  borderRadius: "25px",
                  fontSize: "14px",
                }}
              >
                Delete Appointment
              </Button>
              <Button
                variant="primary"
                className="mx-2"
                style={{ color: "#000" }}
                onClick={closeModal}
                size="lg"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="mx-2"
                style={{ color: "#000" }}
                onClick={() => editAppointment(appointmentData.id)}
                size="lg"
                disabled={
                  scheduleData.title === "" ||
                  scheduleData.fullName === "" ||
                  scheduleData.resident === "" ||
                  scheduleData.email === "" ||
                  scheduleData.phone === ""
                    ? true
                    : false
                }
              >
                Edit Appointment
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(ModifySchedule);
