export const VideoDataByYear = {
  currentYear: [
    {
      id: 1,
      video:
        "https://mountainmanorbucket.s3.us-west-1.amazonaws.com/mountainmanorassets/MMC-Shoptalk-V2.mp4",
      poster: "/images/mmshortpostcare.jpg",
      tag: "Featured",
      header: "Experience Exceptional Healthcare",
      paragraph:
        "Our team is dedicated to paving the way for residents seeking healthcare, delivering top-quality care that exceeds expectations. Come see for yourself how we can help you achieve your health goals and live your best life.        ",
    },
    {
      id: 2,
      video:
        "https://mountainmanorbucket.s3.us-west-1.amazonaws.com/mountainmanorassets/MMC-VirtWlk-V1-2023.mp4",
      poster: "/images/facilityimages/mmfacility.webp",
      tag: "Featured",
      header: "Mountain Manor Virtual Tour",
      paragraph:
        "With our virtual tour, you can experience the beauty of our grounds, the comfort of our private rooms, and the welcoming atmosphere of our communal areas. Our skilled nursing facility is designed to provide high-quality, personalized care to our residents while fostering a sense of community and belonging.",
    },
    {
      id: 3,
      video:
        "https://mountainmanorbucket.s3.us-west-1.amazonaws.com/mountainmanorassets/MMC-Sterling-V1-2023.mp4",
      poster: "/images/mmcustomersatisfaction.webp",
      tag: "Featured",
      header: "Mountain Manor's Team Effort",
      paragraph:
        "Hear about how Mountain Manor has helped Sterling and emphasizes the rehabilitation services provided by the facility, and how they help residents regain their independence and improve their quality of life.",
    },
  ],

  prior: [
    {
      id: 1,
      video:
        "https://mountainmanorbucket.s3-us-west-1.amazonaws.com/mountainmanorassets/MMPromo.mp4",
      poster: "/images/vodthumbnail.jpg",
      tag: "Featured",
      header: "Mountain Manor's Team Effort",
      paragraph:
        "Mountain Manor is an exceptional facility that is reputable and offers top quality care to our patients. Learn more about our philosophy and how he can help you and your loved one.",
    },
    {
      id: 2,
      video:
        "https://mountainmanorbucket.s3-us-west-1.amazonaws.com/mountainmanorassets/MMCovidInfo.mp4",
      poster: "/images/mmlogomain.jpg",
      tag: "Information",
      header: "Covid-19 Awareness",
      paragraph:
        "Mountain Manor has taken steps to keep your loved one safe during this pandemic. Learn more about what we are doing about optimal infection control.",
    },
    {
      id: 3,
      video:
        "https://mountainmanorbucket.s3.us-west-1.amazonaws.com/mountainmanorassets/FINAL-MMC-MONDAY-compressed.mp4",
      poster: "/images/2021-monday.webp",
      tag: "Featured",
      header: "The Monday Uwaidb Story",
      paragraph:
        "Monday is a dedicated Certified Nursing Assistant (CNA) at Mountain Manor, embodies the essence of compassionate caregiving. His role is vital in ensuring the well-being and comfort of residents, as he diligently tends to their needs with empathy and expertise.",
    },
    {
      id: 4,
      video:
        "https://mountainmanorbucket.s3.us-west-1.amazonaws.com/mountainmanorassets/FINAL-MMC-DAVIDBELL-compressed.mp4",
      poster: "/images/2021-davidbell.webp",
      tag: "Featured",
      header: "The David Bell Story",
      paragraph:
        "David Bell's story is a testament to the transformative impact that Mountain Manor Assisted Living has had on his life. Facing various challenges and the need for specialized care, David found solace and support in the compassionate environment provided by Mountain Manor.",
    },
  ],
};
