import serviceBase from "./serviceBase";

const scheduleService = {
  getSchedule: (data) => serviceBase.post("/api/getSchedule", data),
  todaySchedule: (data) => serviceBase.post("/api/todaySchedule", data),
  weekSchedule: (data) => serviceBase.post("/api/weekSchedule", data),
  yesterdaySchedule: (data) => serviceBase.post("/api/yesterdaySchedule", data),
  lastDays: (data) => serviceBase.post("/api/lastDays", data),
  pdfTemplate: (data) => serviceBase.post("/api/pdfTemplate", data),
};

export default scheduleService;
