import base64 from "base-64";
// If site is in production or localhost, also for testing change the port number to 3000 instead of 3312 to view in browser rather than download file independently.
let siteString = "";
let siteUrl = window.location.hostname;
if (siteUrl == "localhost") {
  // Localhost Url
  siteString = "http://localhost:3312/reports/";
} else if (siteUrl == "13.57.33.209") {
  // Staging Url
  siteString = "http://13.57.33.209/reports/";
} else {
  // Production Url
  siteString = "https://mountainmanorskillednursing.com/reports/";
}

// const siteURL =
//   window.location.hostname == "localhost"
//     ? "http://localhost:3312/reports/"
//     : "https://mountainmanorskillednursing.com/reports/";

var appController = {
  encodeData(data) {
    let encodeString = data;
    for (let i = 0; i < 10; i++) {
      encodeString = base64.encode(encodeString);
    }
    return encodeString;
  },
  decodeData(data) {
    let decodeString = data;
    for (let i = 0; i < 10; i++) {
      decodeString = base64.decode(decodeString);
    }
    return decodeString;
  },
  friendlyUrl(data) {
    let str = data;
    str = str.replace(/\s+/g, "-").toLowerCase();
    return str;
  },
  removeHyphen(data) {
    let str = data;
    str = str.split("-").join(" ");
    return str;
  },
  userData() {
    const getuserData = localStorage.getItem("userData");
    const userData = JSON.parse(getuserData);

    return userData;
  },
  converttoCelcius(temp) {
    let temperature = temp;
    let cTemp = (temperature - 32) * (5 / 9);

    return cTemp;
  },
  converttoISO(date) {
    let originalDate = date;

    let isoDate = originalDate.toISOString();

    //console.log(isoDate);

    return isoDate;
  },
  previousDay(data) {
    let date = data;

    let previousDay = date.setDate(date.getDate() - 1);

    return previousDay;
  },
  isAdmin() {
    let userData;
    if (localStorage.getItem("userData") !== null) {
      userData = JSON.parse(localStorage.getItem("userData"));
      if (userData.role_id === 3) {
        return userData;
      }
    }
  },
  downloadPDF() {
    return siteString;
  },
};

export default appController;
