import React from "react";

import AOS from "aos"; // Animate on scroll

const Rehabilitation = () => {
  AOS.init();

  return (
    <div className="rehabContainer">
      <h3 className="py-5 text-center font-weight-bold text-dark">
        Rehabilitation
      </h3>
      <div
        className="container"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="500"
        data-aos-once="true"
      >
        <div className="row">
          <div className="col-sm-12">
            <img
              className="w-100"
              src="/images/new/mmrehabpicture.webp"
              alt="Mountain Manor Rehabilitation Services"
            />
          </div>
          <div className="col-sm-12">
            <p className="p-2">
              At Mountain Manor we want to make your rehab experience enjoyable
              and interactive. To this end we offer multiple tools for our rehab
              team to help each resident reach their goals including, SciFit
              total body exerciser, NuStep recumbent stepper, shortwave
              diathermy, E-Stim, ultrasound, and the state-of-the art Jintronix.
            </p>
            <p className="p-2">
              Our rehab team includes <strong>Physical Therapists</strong>,{" "}
              <strong>Occupational Therapists</strong>, and{" "}
              <strong>Speech Therapists</strong> ready to do a full evaluation
              and create a detailed plan of care for you within 24 hours of
              admission. The plan of care will focus specifically on each
              resident’s limitations and work towards increased mobility and
              independence. Our goal is to help you return home as soon as
              possible.
            </p>
          </div>
        </div>
      </div>
      <div className="rehabTreatment my-3">
        <div className="fluid-container">
          <div className="container shadow-lg">
            <div className="row" data-aos="fade-down" data-aos-once="true">
              <h3 className="text-center p-4" style={{ color: "#06A7D3" }}>
                <strong>What is Jintronix?</strong>
              </h3>
              <p>
                A virtual reality therapy system, the Jintronix enables
                functional rehabilitation for aging adults and others with
                physical limitations. This easy-to-use system incorporates an
                advanced 3D camera and specialized computer to capture your
                patient's movements and allows them to interact in a virtual
                world. Jintronix helps to address patient's cognitive impairment
                by incorporating activities that utilize memory and executive
                function. Virtual reality helps make therapy more fun and
                engaging for your patients and motivates them to exercise harder
                and longer while improving their functional outcomes.
              </p>
              <div
                className="col-md-12"
                data-aos="fade-down"
                data-aos-offset="300"
                data-aos-once="true"
              >
                <h3 className="text-dark py-3">
                  Common treatment focuses for all rehab disciplines may
                  include:
                </h3>
                <ul id="bulletList">
                  <li>
                    Increasing strength and mobility to upper and lower
                    extremities
                  </li>
                  <li>
                    Improving coordination and balance while walking
                    post-surgery
                  </li>
                  <li>
                    Improving strength conditioning and range of motion
                    post-surgery
                  </li>
                  <li>Pain Management</li>
                  <li>
                    Improving body mechanics to help prevent future injuries
                  </li>
                  <li>Promoting Safety Awareness</li>
                  <li>
                    Improving functional independence in all activities of daily
                    living (eating, dressing, toileting, cooking, etc…)
                  </li>
                  <li>Training on use of adaptive equipment</li>
                  <li>
                    Improving chewing and ability to swallow regular textured
                    food
                  </li>
                  <li>
                    Maximizing cognitive language skills and increasing
                    comprehension problem solving
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rehabilitation;
