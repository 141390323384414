import React from "react";

import { MuiThemeProvider } from "@material-ui/core/styles";
// Global material ui theme styles
import MyTheme from "./Theme";

import Formservice from "../../services/Formservice";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import PropTypes from "prop-types";
import Moment from "moment";
import Grid from "@material-ui/core/Grid";
import DatePicker from "react-date-picker";
import MaskedInput from "react-text-mask";
import ReCAPTCHA from "react-google-recaptcha";

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class Calltoaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fullName: "",
      fullNameError: "",
      email: "",
      emailError: "",
      phoneNumber: "",
      phoneNumberError: "",
      textmask: "(1  )    -    ",
      date: new Date(),
      captchaVerify: false,
    };
  }

  onChange = (e, string) => {
    this.setState({
      [e.target.name]: e.target.value,
      [string]: event.target.value,
    });
  };

  changeDate = (date) => this.setState({ date });

  captchaVertify = (value) => {
    const capVerify = value;
    if (capVerify !== "") {
      this.setState({
        captchaVerify: true,
      });
    }
    //console.log(this.state);
  };

  formValidate = () => {
    let isError = false;
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const errors = {
      fullNameError: "",
      emailError: "",
      phoneNumberError: "",
      captchaError: "",
    };

    if (this.state.fullName === "") {
      isError = true;
      errors.fullNameError = "* Please enter your full name";
    }

    if (this.state.email.indexOf("@") === -1) {
      isError = true;
      errors.emailError = "* Please enter a valid email address";
    }

    if (phoneRegex.test(this.state.phoneNumber) === false) {
      isError = true;
      errors.phoneNumberError = "* Please enter a valid phone number";
    }

    if (this.state.captchaVerify === false) {
      isError = true;
      errors.captchaError = "* Please verify you're not a robot";
    }

    this.setState({
      ...this.state,
      ...errors,
      isError,
    });

    return isError;
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const err = this.formValidate();

    if (!err) {
      // clear the form
      this.setState({
        fullName: "",
        fullNameError: "",
        email: "",
        emailError: "",
        phoneNumber: "",
        phoneNumberError: "",
        //captchaVerify: false,
        date: new Date(),
      });

      //console.log("form sent!");

      const successData = {
        fullName: this.state.fullName,
        email: this.state.email,
        phone: this.state.phoneNumber,
        tourDate: Moment(this.state.date).format("MM-DD-YYYY hh:mm a"),
      };

      const callData = await Formservice.calltoactionAuth(successData);

      this.props.handleClose();

      alert("Thank you. Your tour has been successfully scheduled");
    }
  };

  render() {
    const { textmask } = this.state;
    return (
      <div className="calltoactionContainer">
        <MuiThemeProvider theme={MyTheme}>
          <form
            method="POST"
            noValidate
            autoComplete="off"
            onSubmit={(e) => this.onSubmit(e)}
          >
            <div className="form-row">
              <Grid container wrap="nowrap">
                <Grid item xs>
                  <TextField
                    style={{ padding: 0 }}
                    InputLabelProps={{ shrink: true }}
                    name="fullName"
                    value={this.state.fullName}
                    type="text"
                    label="Full Name"
                    error={this.state.fullNameError ? true : false}
                    helperText={this.state.fullNameError}
                    variant="outlined"
                    onChange={(e) => this.onChange(e)}
                  />
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    name="email"
                    type="email"
                    value={this.state.email}
                    label="Email Address"
                    error={this.state.emailError ? true : false}
                    helperText={this.state.emailError}
                    variant="outlined"
                    onChange={(e) => this.onChange(e)}
                  />
                </Grid>
              </Grid>
            </div>
            <div className="form-row">
              <Grid container wrap="nowrap">
                <Grid item xs>
                  <FormControl>
                    <InputLabel
                      htmlFor="formatted-text-mask-input"
                      style={
                        this.state.phoneNumberError ? { color: "red" } : null
                      }
                    >
                      {this.state.phoneNumberError
                        ? this.state.phoneNumberError
                        : "Phone Number"}
                    </InputLabel>
                    <Input
                      type="tel"
                      value={textmask}
                      name="phoneNumber"
                      onChange={(e) => this.onChange(e, "textmask")}
                      inputComponent={TextMaskCustom}
                      error={this.state.phoneNumberError ? true : false}
                    />
                  </FormControl>
                  <DatePicker
                    name="date"
                    onChange={this.changeDate}
                    minDate={new Date()}
                    value={this.state.date}
                  />
                </Grid>
              </Grid>
            </div>
            <div className="form-row">
              <Grid container wrap="nowrap">
                <Grid item xs>
                  <FormControl>
                    <ReCAPTCHA
                      sitekey={process.env.RECAPTCHA_API_KEY}
                      onChange={this.captchaVertify}
                    />
                    {this.state.captchaVerify === false ? (
                      <p style={{ textAlign: "left", color: "red" }}>
                        {this.state.captchaError}
                      </p>
                    ) : null}
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <div className="form-row">
              <Button variant="outlined" type="submit">
                SUBMIT
              </Button>
            </div>
          </form>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default Calltoaction;
