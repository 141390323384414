import React from "react";

const VideoComponent = ({ template, currentYearData, priorYearData }) => {
  const renderVideos = (data) => {
    return data.map((rows, index) => (
      <div className="col-lg-6" key={index}>
        <div className="card text-center my-3">
          <div className="card-header">
            <div className="text-right">
              {" "}
              <span
                className={`badge badge-pill ${
                  rows.tag === "Featured" ? "badge-primary" : "badge-info"
                }`}
              >
                {rows.tag}
              </span>
            </div>
            <div className="text-center my-4">
              <h4>
                <strong>{rows.header}</strong>
              </h4>
            </div>
          </div>
          <div className="card-body">
            <div className="cardVideo">
              <video
                controls
                style={{ width: "100%", height: "100%" }}
                poster={rows.poster ? rows.poster : null}
              >
                <source src={rows.video} type="video/mp4" />
              </video>
            </div>
            <p className="card-text text-left pt-3">{rows.paragraph}</p>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="section-video-container">
      <div className="row justify-content-center">
        {template === 1 &&
          currentYearData.length > 0 &&
          renderVideos(currentYearData)}
        {template === 2 &&
          priorYearData.length > 0 &&
          renderVideos(priorYearData)}
      </div>
    </div>
  );
};

export default VideoComponent;
