import React from "react";

import AOS from "aos"; // Animate on scroll

const FacilityAmenities = () => {
  AOS.init({
    duration: 2000,
  });
  AOS.refresh();

  return (
    <div className="facilityamenitiesContainer">
      <div className="facilityGallery">
        <div className="fluid-container">
          <div className="container">
            {/* Facility Amenities */}
            <div className="row">
              <div className="col-md-12 text-center">
                <h3 className="py-3 text-dark font-weight-bold">
                  Facility Amenities
                </h3>
                <p>
                  Mountain Manor offers a clean, comfortable and odor free
                  environment for our residents.
                </p>
              </div>
            </div>
            <div className="row" data-aos="fade-down" data-aos-once="true">
              <div className="col-md-6">
                <img
                  src="/images/mmsignviewfront.jpg"
                  alt="Mountain Manor Facility"
                />
              </div>
              <div className="col-md-6 whiteBackground">
                <h3>Mountain Manor Facility</h3>
              </div>
            </div>
            {/* Facility Room */}
            <div className="row" data-aos="fade-down" data-aos-once="true">
              <div className="col-md-6 whiteBackground">
                <h3>Mountain Manor Rooms</h3>
              </div>
              <div className="col-md-6">
                <img
                  src="/images/facilityimages/mmfacilityroom.jpg"
                  alt="Mountain Manor Rooms"
                />
              </div>
            </div>
            {/* Facility Dining Room */}
            <div className="row" data-aos="fade-down" data-aos-once="true">
              <div className="col-md-6">
                <img
                  src="/images/facilityimages/mmfacilityflower.jpg"
                  alt="Mountain Manor Dining Rooms"
                />
              </div>
              <div className="col-md-6 whiteBackground">
                <h3>Mountain Manor Dining Room</h3>
              </div>
            </div>
            {/* Facility Garden Patio */}
            <div className="row" data-aos="fade-down" data-aos-once="true">
              <div className="col-md-6 whiteBackground">
                <h3>Mountain Manor Courtyard</h3>
              </div>
              <div className="col-md-6">
                <img
                  src="/images/facilityimages/mmoutsidepatio.jpg"
                  alt="Mountain Manor Outside Patio"
                />
              </div>
            </div>
            {/* Facility Rehab Gym */}
            <div className="row" data-aos="fade-down" data-aos-once="true">
              <div className="col-md-6">
                <img
                  src="/images/facilityimages/mmstaffcare.jpg"
                  alt="Mountain Manor Rehababilitation Gym"
                />
              </div>
              <div className="col-md-6 whiteBackground">
                <h3>Mountain Manor Rehabilitation Gym</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacilityAmenities;
