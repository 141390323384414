import React from "react";

import MyTheme from "../common/Theme";

import { MuiThemeProvider } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import AOS from "aos"; // Animate on scroll
import Base64 from "base-64";
import { Redirect } from "react-router-dom";

import loginService from "../../services/Loginservice";

class Intranet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailError: "",
      password: "",
      showPassword: false,
      passwordError: "",
      formError: false,
    };
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  formValidate = () => {
    let isError = false;
    const errors = {
      emailError: "",
      passwordError: "",
      formError: "",
      credsError: "",
    };

    // Check if a valid email address
    if (this.state.email.indexOf("@") === -1) {
      isError = true;
      errors.emailError = "* Please enter a valid email address";
    }

    if (this.state.password === "") {
      errors.passwordError = "* Please enter a password";
    }

    if (this.state.email === "" || this.state.password === "") {
      errors.formError = "Please fill out all fields";
    }

    // make a copy of this into state
    this.setState({
      ...this.state,
      ...errors,
      isError,
    });

    return isError;
  };

  onSubmit = async (e) => {
    e.preventDefault();

    //console.log(this.state);

    const err = this.formValidate();

    if (!err) {
      const data = {
        email: this.state.email,
        password: this.state.password,
        passwordHash: Base64.encode(this.state.password),
      };

      const userData = await loginService.authCheck(data);

      //console.log(userData);

      if (userData.length === 0) {
        this.setState({
          formError: true,
        });
      } else {
        this.setState({
          formError: false,
        });
        localStorage.setItem("userData", JSON.stringify(userData[0]));
        location.href = "/adminDashboard";
        // this.props.history.push("/adminDashboard");
      }
    }
  };

  componentDidMount() {
    AOS.init();
  }

  render() {
    return (
      <div className="intranetContainer">
        <div className="fluid-container">
          <div className="bgimage">
            <div className="container">
              <div className="intranetForm">
                <MuiThemeProvider theme={MyTheme}>
                  <form
                    method="POST"
                    noValidate
                    autoComplete="off"
                    onSubmit={(e) => this.onSubmit(e)}
                  >
                    <div className="formContainer">
                      <Grid container>
                        <Grid item xs={12} data-aos="fade-down">
                          <i className="fas fa-user-circle fa-3x" />
                        </Grid>
                        <Grid item xs={12} data-aos="fade-down">
                          <h2 className="text-center">Sign in</h2>
                          {this.state.formError === true ? (
                            <p style={{ color: "red" }}>Invalid Credentials.</p>
                          ) : null}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            name="email"
                            value={this.state.email}
                            type="text"
                            label="Email Address"
                            error={this.state.emailError ? true : false}
                            helperText={this.state.emailError}
                            variant="outlined"
                            onChange={(e) => this.onChange(e)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="outlined-adornment-password"
                            InputLabelProps={{ shrink: true }}
                            name="password"
                            type={this.state.showPassword ? "text" : "password"}
                            value={this.state.password}
                            label="Password"
                            error={this.state.passwordError ? true : false}
                            helperText={this.state.passwordError}
                            variant="outlined"
                            onChange={(e) => this.onChange(e)}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                  >
                                    {this.state.showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button variant="outlined" type="submit">
                            SUBMIT
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </form>
                </MuiThemeProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Intranet;
