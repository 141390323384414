import React, { useRef } from "react";

import { Helmet } from "react-helmet";

import Assistedliving from "./Assistedliving";
import Rehabilitation from "./Rehabilitation";
import FacilityAmenities from "./Facilityamenities";
import SkilledNursing from "./Skillednursing";

export const Services = () => {
  const skilledNursingRef = useRef(null);
  const assistedLivingRef = useRef(null);
  const rehabilitationRef = useRef(null);
  const facilityAmenitiesRef = useRef(null);

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="root-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mountain Manor Services</title>
        <meta
          name="description"
          content="Explore the range of services offered by Mountain Manor Skilled Nursing & Assisted Living. From Skilled Nursing and Assisted Living to Rehabilitation and Facility Amenities, we provide exceptional resident experiences in the region."
        />
        <link
          rel="canonical"
          href="https://mountainmanorskillednursing.com/services"
        />
      </Helmet>
      <h1 className="text-center py-5">Mountain Manor Services</h1>
      {/* Category pick */}
      <div className="categories">
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="category-items">
              <img
                src="/images/new/skilled-nursing-photo-v2.webp"
                alt="Skilled Nursing Services"
              />
              <div
                className="category-action"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <h3 className="text-center font-weight-bold">
                  Skilled Nursing
                </h3>
                <button
                  className="btn btn-primary text-uppercase mt-5"
                  onClick={() => scrollToRef(skilledNursingRef)}
                >
                  View Skilled Nursing
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="category-items">
              <img
                src="/images/new/assisted-living-photo.webp"
                alt="Assisted Living Services"
              />
              <div
                className="category-action"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <h3 className="text-center font-weight-bold">
                  Assisted Living
                </h3>
                <button
                  className="btn btn-primary text-uppercase mt-5"
                  onClick={() => scrollToRef(assistedLivingRef)}
                >
                  View Assisted Living
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="category-items">
              <img
                src="/images/new/rehabilitation-photo-v2.webp"
                alt="Rehabilitation Services"
              />
              <div
                className="category-action"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <h3 className="text-center font-weight-bold">Rehabilitation</h3>
                <button
                  className="btn btn-primary text-uppercase mt-5"
                  onClick={() => scrollToRef(rehabilitationRef)}
                >
                  View Rehabilitation
                </button>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="category-items">
              <img
                src="/images/new/facility-amenities-photo.webp"
                alt="Facility Amenities Services"
              />
              <div
                className="category-action"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <h3 className="text-center font-weight-bold">
                  Facility Amenities
                </h3>
                <button
                  className="btn btn-primary text-uppercase mt-5"
                  onClick={() => scrollToRef(facilityAmenitiesRef)}
                >
                  View Facility Amenities
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Services */}
      <div className="services-container">
        <div className="hero-container">
          <div className="row align-items-center">
            <div className="col-sm-12 order-sm-2 col-lg-6 order-lg-1">
              <div className="quote-container">
                <span className="quote-start">&ldquo;</span>
                <p className="quote-text pl-5">
                  Discover Unparalleled Short-Term Post-Acute Care at Mountain
                  Manor – Your Premier Destination for Exceptional Resident
                  Experiences in the Region!
                </p>
                <span className="quote-end">&rdquo;</span>
              </div>
            </div>
            <div className="col-sm-12 order-sm-1 col-lg-6 order-lg-2">
              <img
                className="w-100 rounded"
                src="/images/mmoutsideviewexterior.jpg"
                alt="Mountain Manor Short-Term Care"
              />
            </div>
          </div>
        </div>
        {/* Skilled Nursing */}
        <div className="skilled-ref" ref={skilledNursingRef}>
          <SkilledNursing />
        </div>
        {/* Assisted Living */}
        <div className="assisted-ref" ref={assistedLivingRef}>
          <Assistedliving />
        </div>
        {/* Rehab */}
        <div className="rehab-ref" ref={rehabilitationRef}>
          <Rehabilitation />
        </div>
        {/* Facility Amenities */}
        <div className="facility-ref" ref={facilityAmenitiesRef}>
          <FacilityAmenities />
        </div>
      </div>
    </div>
  );
};

export default Services;
